@import "../../../styles/theme.less";
.admin-users-container {
  .artist-cell-roles {
    .ant-tag {
      margin-right: 8px;
      text-transform: capitalize;
    }
  }
}

.admin-user-modal {
  .ant-modal-body {
    padding-bottom: 0;
    .tag-list {
      .ant-tag {
        margin-right: 10px;
        margin-bottom: 10px;
      }
    }
  }
}

.suspend-confirm-modal {
  .ant-modal-title {
    line-height: 38px;
  }
  .ant-modal-body {
    color: #696969;
    ul {
      list-style-type: decimal;
      padding-left: 16px;
      li {
        line-height: 26px;
        &:not(:last-child) {
          margin-bottom: 8px;
        }
      }
    }
  }
}
