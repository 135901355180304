@import "@/styles/theme.less";
.admin-consultation-staff-edit-container {
  h1 {
    margin-bottom: 16px;
  }
  & > p {
    margin-bottom: 40px;
  }
  .staff-form {
    width: 100%;
    padding: 32px;
    background: #ffffff;
    border: 1px solid #e0e0e0;
    box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.08);
    margin-bottom: 24px;
    .radio-item {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;
      border: 0;
      padding-left: 0;
      .ant-form-item-label label {
        font-weight: 900;
        font-size: 16px;
        color: #000;
        margin-right: 42px;
        text-transform: capitalize;
        height: auto;
      }
    }
    .flex {
      & > .ant-form-item {
        flex: 1;
        &:first-child {
          margin-right: 24px;
        }
      }
    }
    .cover-image {
      .children-upload {
        width: 180px;
        height: 180px;
        display: block;
      }
      .ant-upload-drag-icon {
        margin-bottom: 0 !important;
        margin-top: 20px;
        .cover-image-text {
          display: block;
          width: 100%;
          font-size: 16px;
          text-align: center;
          color: #000;
        }
      }
    }
    .upload-item-wrap {
      .ant-upload-select-picture {
        width: 100%;
      }
    }
    .time-option-wrap {
      margin-bottom: 24px;
      & > span {
        font-weight: bold;
        width: 126px;
        font-size: 16px;
      }
      .ant-select {
        margin-right: 24px;
      }
      .ant-radio-button-wrapper:not(:last-child) {
        margin-right: 24px;
      }
      &.repeat-wrap {
        .ant-radio-button-wrapper:not(:last-child) {
          margin-top: 8px;
          margin-right: 24px;
          margin-bottom: 8px;
        }
      }
      .ant-radio-button-checked {
        background: #dfffed;
        border: 2px solid #00ff6d;
        color: #000;
      }
      .ant-radio-button-wrapper-checked:not(
          [class*=" ant-radio-button-wrapper-disabled"]
        ).ant-radio-button-wrapper {
        border: 0;
      }
      .ant-radio-button-wrapper {
        height: 40px;
        line-height: 40px;
        border: 1px solid #e0e0e0;
      }
      .ant-radio-button-wrapper:not(:first-child)::before {
        width: 0;
      }
    }
    .org-item {
      input {
        color: #000;
      }
    }
    h3 {
      font-size: 16px;
      margin-bottom: 16px;
    }
    .price-item {
      margin-bottom: 8px;
      & + p {
        font-size: 12px;
        color: #797f84;
      }
    }
    .switch-item {
      & > span {
        margin-right: 8px;
      }
    }
    .form-item-disabled {
      background-color: #f5f5f5;
    }
    .flex {
      .ant-form-item:first-child {
        margin-right: 0;
      }
    }
  }
  .btn {
    button {
      margin-left: 24px;
    }
  }
  .tooltip-field-item {
    color: #b0b0b0;
    margin-right: 4px;
  }

  .ant-form-item-label
    > label.ant-form-item-required:not(
      .ant-form-item-required-mark-optional
    )::before {
    content: "";
    display: none;
  }

  .ant-form
    .ant-form-item
    .ant-form-item-label
    label.ant-form-item-required:after {
    margin-left: 3px;
  }

  .ant-divider-horizontal {
    margin: 40px 0px;
  }

  .ant-input:focus {
    box-shadow: none !important;
  }

  .btn-add-service {
    font-weight: 900;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    color: #000000;
    &:hover {
      text-decoration-line: underline;
    }
  }

  .btn-service {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    display: flex;
    align-items: center;
    color: #000000;
    cursor: pointer;
    span {
      font-weight: 400;
    }
    .mr-3 {
      margin-right: 11px;
    }
  }

  .ant-select-selector input {
    font-size: 16px !important;
    line-height: 26px !important;
  }

  .ant-select-selection-item {
    font-size: 16px !important;
  }

  .ant-select-disabled.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    color: #a0a0a0;
  }
}

.checkbox-select-dropdown {
  .ant-select-item-option {
    &.ant-select-item-option-selected {
      background-color: #fff;
      font-weight: normal;
      .anticon-check-square {
        color: #05a5ff;
        margin-right: 12px;
        font-size: 18px;
      }
    }
    .ant-select-item-option-state {
      display: none !important;
    }
    .ant-select-item-option-content {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .uncheck {
        display: inline-block;
        width: 17px;
        height: 17px;
        border: 1px solid #696969;
        margin-right: 12px;
      }
    }
    &.select-all-option {
      color: #000;
      cursor: pointer;
      .uncheck {
        display: inline-block;
        width: 17px;
        height: 17px;
        border: 1px solid #696969;
        margin-right: 12px;
      }
      .anticon-check-square,
      .anticon-minus-square {
        color: #05a5ff;
        margin-right: 12px;
        font-size: 18px;
      }
    }
  }
}
