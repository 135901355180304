@import "../../styles/theme.less";
.admin-layout-container {
  width: 100%;
  height: 100%;
  aside {
    height: 100vh;
    position: relative;
    .logo {
      display: block;
      width: 100%;
      height: auto;
      background-color: #fff;
      padding: 24px 0 18px 24px;
      border-bottom: 1px solid #e0e0e0;
    }
    .ant-menu.admin-side-menu {
      flex: 1;
      overflow-y: auto;
      overflow-x: hidden;
      font-size: 16px;
      .ant-menu-item {
        height: 64px;
        margin: 0;
        display: flex;
        align-items: center;
        padding: 20px;
        .red-dot {
          display: inline-block;
          width: 12px;
          height: 12px;
          background: red;
          border-radius: 50%;
          position: relative;
          top: 2px;
          margin-left: 8px;
        }
      }
      .ant-menu-submenu {
        .ant-menu-submenu-title {
          height: 64px;
          line-height: 72px;
          display: flex;
          align-items: center;
          span {
            position: relative;
            padding-left: 12px;
          }
        }
        .ant-menu-sub {
          padding: 0px;
          //& > li.ant-menu-item-only-child,
          //&
          //  > li.ant-menu-submenu.ant-menu-submenu-inline
          //  > .ant-menu-submenu-title {
          //  padding-left: 60px !important;
          //}
        }
      }
    }
    .admin-avatar-bar {
      width: 100%;
      flex-shrink: 0;
      .user-wrap {
        img {
          width: 24px;
          height: 24px;
          margin-right: 16px;
          border-radius: 24px;
        }
      }
      .admin-func-item {
        padding-left: 44px;
        line-height: 50px;
        cursor: pointer;
      }
    }
  }
  .main-content {
    width: 100%;
    height: 100vh;
    overflow: auto;
  }
}
