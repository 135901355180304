@import "../theme";

.app-container {
  header.home-wrap {
    height: 72px;
    flex: 0 0 72px;
    a {
      color: #000;
    }
    .logo-item {
      height: 24px;
    }
    .menu-item {
      width: 572px;
      line-height: 72px;
      border-bottom: 0;
      background-color: transparent;
      .ant-menu-submenu-active {
        .ant-menu-submenu-title {
          .anticon-down {
            transform: rotate(-180deg);
          }
        }
      }
      .ant-menu-submenu-title {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        .anticon-down {
          margin-left: 8px;
          margin-right: 0;
          transition: transform 0.4s;
        }
      }
      a:not(:last-child) {
        margin-right: 40px;
      }
    }
    button {
      margin-left: 16px;
    }
    .user-item {
      height: 100%;
      display: flex;
    }
    .user-signin {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .ant-dropdown-trigger {
      height: 100%;
      display: flex;
      align-items: center;
      img {
        width: 24px;
        height: 24px;
        border-radius: 24px;
        margin-right: 8px;
      }
      span {
        margin-left: 12px;
        transition: all 0.4s;
      }
      &.ant-dropdown-open {
        span {
          transform: rotate(-180deg);
        }
      }
    }
    @media @desktop {
      .icon-menu {
        display: none;
      }
    }

    @media @mobile {
      position: sticky;
      top: 0;
      z-index: 999;
      padding: 0 32px / @pv;
      background-color: #fff;
      box-shadow: inset 0px -1px 0px #e0e0e0;
      .logo-item {
        height: 24px / @pv;
      }
      .user-item,
      .menu-item {
        display: none;
      }
      .mobile-header-menu {
        .ant-menu-submenu-title .ant-menu-submenu-arrow {
          display: block;
          color: inherit;
          right: 28px;
        }
        .avatar-info {
          img {
            width: 24px / @pv;
            height: 24px / @pv;
            border-radius: 32px;
            margin-right: 8px / @pv;
          }
        }
      }
    }
  }
}
