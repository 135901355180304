@import "@/styles/theme.less";
.admin-consultation-staff-list-container {
  .search-container {
    .filter-item {
      width: 56px;
      height: 56px;
      flex: 0 0 56px;
      border: 1px solid #e0e0e0;
      margin-left: 16px;
      cursor: pointer;
    }
  }

  .field-form-items {
    overflow: hidden;
  }
  .tf-autocomplete {
    .ant-select-selection-placeholder,
    .ant-select-selector::after {
      line-height: 25px;
    }
    .ant-select-selector {
      border: none;
      outline: none;
      box-shadow: none !important;
    }
    .ant-select-selection-search {
      left: 0px;
    }
  }

  .ant-table-thead {
    .ant-table-cell {
      white-space: nowrap;
    }
  }
}

.admin-consultation-staff-menu {
  .ant-dropdown-menu-item {
    padding: 15px 30px 15px 24px;
  }
}

.no-style-item.ant-form-item {
  padding: 0px;
  border: none;
  &.ant-form-item-has-error {
    outline: 1px solid #ff4d4f;
    outline-offset: 10px;
  }
  label {
    margin-bottom: 16px;
  }
  h3 {
    text-transform: none;
    margin-bottom: 0px !important;
  }
}
