.ant-switch-checked {
  background-color: #70C861 !important;
}

.ant-radio-wrapper-disabled {
  background-color: #F1F1F6 !important;
  color: #DDDFE4 !important;
}

.ant-form-item .ant-input-textarea-show-count::after {
  margin-bottom: 0px !important;
}