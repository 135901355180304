@import "theme";

@font-face {
  font-family: Roboto;
  src: url("../font/Roboto-Regular.ttf");
}
@font-face {
  font-family: RobotoBold;
  src: url("../font/Roboto-Bold.ttf");
}

body {
  margin: 0;
  font-family: Roboto !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

h1,
h2,
h3,
button {
  font-family: RobotoBold;
  font-weight: 900 !important;
}

i {
  font-style: normal;
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  font-size: 14px;
  -webkit-text-fill-color: #000 !important;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
  background-color: transparent;
  background-image: none;
  transition: background-color 50000s ease-in-out 0s; //背景色透明  生效时长  过渡效果  启用时延迟的时间
}
input {
  background-color: transparent;
}
.input-num-no-stepper > input {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    display: none;
  }
}

.ant-alert-info {
  background-color: #e6f4ff;
  border: 1px solid #91caff;
}

.app-container {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
  min-height: 100%;
  background-color: #f9f9f9;
  header,
  main,
  footer {
    width: 100%;
    padding: 0 140px;
    @media @mobile {
      padding: 0;
    }
  }
  main {
    flex: 2;
    &.content-main {
      overflow-y: auto;
    }
  }
  footer {
    background-color: #000;
    color: #fff;
    padding: 15px 140px;
    svg {
      width: 94px;
      margin-right: 8px;
    }
    a {
      color: #fff;
      letter-spacing: 1px;
      &:not(:last-child) {
        margin-right: 32px;
      }
    }
    p {
      margin-bottom: 0;
      @media @mobile {
        &.flex {
          flex-flow: column nowrap;
          span {
            flex: 1 !important;
            width: 100%;
          }
        }
      }
    }
    @media @mobile {
      padding: 20px / @pv 30px / @pv;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      span {
        width: 100%;
        &:first-child {
          margin-bottom: 8px / @pv;
        }
        &:last-child {
          &.flex {
            justify-content: flex-start;
            flex-flow: column;
            align-items: flex-start;
            margin-bottom: 18px;
            a {
              margin-top: 16px;
            }
          }
        }
      }
    }
  }
  .social-follow {
    background-color: #000;
    margin: 0 -140px;
    padding: 64px 140px;
    color: #fff;
    justify-content: center;
    h1 {
      color: inherit;
      margin-bottom: 0 !important;
    }
    position: relative;
    &:after {
      content: "";
      display: block;
      height: 1px;
      background-color: #313335;
      position: absolute;
      left: 140px;
      right: 140px;
      bottom: 0;
    }
    span.flex {
      flex: 0 0 238px;
      font-size: 18px;
      a {
        display: block;
        padding: 14px;
        line-height: 1;
        border: 1px solid #505050;
        margin-bottom: 18px;
        svg {
          color: #fff;
        }
      }
    }
    a.elementor-button-link {
      background-color: #fdffdd;
      border-radius: 2px;
      padding: 10px 28px;
      float: right;
      right: 0;
      top: 4px;
      color: black;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: bold;
      font-family: "Roboto";
      letter-spacing: 1.4px;
      line-height: 1;
    }
    @media @mobile {
      padding: 0;
      margin: 0;
      &:after {
        left: 14px / @pv;
        right: 14px / @pv;
      }
      & > .flex {
        padding: 20px / @pv;
        padding-bottom: 6px / @pv;
        flex-flow: column nowrap;
        align-items: flex-start;
        h1 {
          margin-bottom: 14px / @pv !important;
          font-size: 22px / @pv;
        }
        span.flex {
          flex: 1;
          width: 238px;
          font-size: 14px / @pv;
          a {
            height: 22px / @pv;
            width: 22px / @pv;
            padding: 4px / @pv;
          }
          svg {
            font-size: 14px / @pv;
          }
        }
      }
      a.elementor-button-link {
        float: none;
        margin: 0 20px / @pv;
        position: relative;
        top: -6px / @pv;
      }
    }
  }
}

.ant-form {
  .ant-form-item {
    &.textarea-wrap {
      textarea {
        min-height: 80px !important;
      }
    }
    border: 1px solid #e0e0e0;
    padding: 9px 16px 5px;
    margin-bottom: 24px;
    &:focus {
      border-color: #000;
    }
    &.ant-form-item-has-error {
      border-color: #ff4d4f;
      .ant-form-item-label label,
      .ant-select-selector {
        color: #ff4d4f;
        background-color: #fff !important;
      }
    }
    &.no-style {
      border: 0;
      padding: 0;
    }
    .ant-form-item-explain,
    .ant-form-item-explain-error {
      display: none !important;
    }
    .ant-form-item-label {
      text-transform: uppercase;
      line-height: 1;
      padding: 0;
      label {
        font-size: 10px;
        color: #a0a0a0;
        // color: #797F84;
        font-weight: bold;
        height: auto;
        letter-spacing: 1px;
        &:before {
          content: "";
          display: none;
        }
        &.ant-form-item-required {
          &:after {
            display: inline-block;
            width: 12px;
            height: 12px;
            line-height: 12px;
            content: "*";
            color: #ff4d4f;
          }
        }
      }
    }
    &.field-form-item-required {
      .field-form-items {
        & > span:first-child {
          &:after {
            display: inline-block;
            width: 12px;
            height: 12px;
            line-height: 12px;
            content: "*";
            color: #ff4d4f;
          }
        }
      }
    }
    .ant-form-item-control-input {
      .ant-input-number{
        border: none;
      }
      .ant-input-affix-wrapper {
        padding: 0;
        border: 0;
        box-shadow: none;
        outline: none;
        background: transparent;
      }
      height: auto;
      min-height: 0;
      .ant-input,.ant-input-number-input {
        border: 0px;
        padding: 0;
        height: 26px;
        line-height: 26px;
        min-height: auto;
        font-size: 16px;
        color: #000;
        box-shadow: none;
        outline: none;
        &[disabled] {
          background-color: transparent;
          color: #a0a0a0;
        }
        &::placeholder {
          font-size: 14px;
        }
      }
      .ant-input-search {
        .ant-input-group-addon {
          button {
            padding: 0;
            border: none;
            height: 26px;
          }
        }
      }
    }
    &.select-container {
      padding-bottom: 3.5px;
    }
    .ant-select-selector,
    .ant-select {
      border: none !important;
      width: 100%;
      padding: 0 !important;
      height: 28px !important;
      line-height: 28px !important;
      box-shadow: none !important;
      &.ant-select-multiple {
        .ant-select-selector {
        }
        .ant-select-selection-overflow {
          height: 24px;
          .ant-select-selection-overflow-item {
            margin: 0 4px 4px 0;
            .ant-select-selection-item {
              margin: 0;
              .ant-select-selection-item-content {
                font-size: 12px;
              }
            }
          }
        }
        .ant-select-selection-search {
          margin: 0;
        }
        &.multiple-row-selector {
          height: auto !important;
          .ant-select-selection-overflow,
          .ant-select-selector {
            height: auto !important;
            .ant-select-selection-overflow-item {
              margin: 2px 4px 2px 0;
            }
          }
        }
      }

      .ant-select-selection-search-input,
      .ant-select-selection-item,
      .ant-select-selection-placeholder {
        height: 28px !important;
        line-height: 28px !important;
        font-size: 14px;
      }
      .ant-select-selection-search {
        left: 0;
      }
    }
    .ant-picker {
      width: 100%;
      box-shadow: none;
      border: 0;
      padding-right: 0;
      margin-left: -12px;
      &.ant-picker-disabled {
        background: transparent;
      }
    }
    .ant-select-multiple .ant-select-selection-placeholder {
      left: 0;
    }
    .ant-select-multiple .ant-select-selection-search {
      margin-inline-start: 0;
    }

    &.youtube-field-item {
      border: 0;
      padding: 0;
      &.ant-form-item-has-error {
        .field-form-items {
          border-color: red;
          & > span {
            color: red;
          }
        }
      }
    }
  }
  .upload-item-wrap {
    margin-bottom: 24px;
    .children-upload {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      background: #f9f9f9;
      border: 1px dashed #e0e0e0;
      padding: 24px;
      cursor: pointer;
      .ant-upload-drag-icon {
        color: #00ff6d;
        font-size: 32px;
        margin-bottom: 16px;
      }
      .ant-upload-text {
        font-size: 16px;
        margin-bottom: 8px;
      }
      .ant-upload-hint {
        color: #696969;
      }
    }
    .ant-upload-list {
      button {
        border: none;
      }
    }
  }
}

.date-picker-wrap {
  .ant-picker-ok {
    button {
      height: 28px;
      padding: 0 16px;
    }
  }
}

.date-picker-mobile {
  @media @mobile {
    left: 50px !important;
    .ant-picker-panel-container {
      width: 85%;
      .ant-picker-panels {
        flex-wrap: wrap;
      }
    }
  }
}

.ele-mobile {
  display: none;
  @media @mobile {
    display: block;
  }
}
.ele-desktop {
  display: none;
  @media @desktop {
    display: inline-flex;
  }
}

.field-form-items {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  padding: 10px 0 4px 16px;
  display: flex;
  flex-direction: column;
  width: 270px;
  & > span:first-child {
    display: block;
    width: 100%;
    font-size: 12px;
    line-height: 14px;
    color: #a0a0a0;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 2px;
    .anticon {
      margin-left: 6px;
    }
  }
  &.range-picker-wrap {
    padding-bottom: 6px;
    input {
      height: 22px;
      line-height: 22px;
    }
  }
  .ant-select {
    height: 24.5px;
    line-height: 24.5px;
    .ant-select-selector {
      padding-left: 0;
      height: 100%;
      input {
        height: 24.5px !important;
        line-height: 24.5px;
      }
    }
    &:not(.ant-select-customize-input) .ant-select-selector {
      border: 0px;
    }
    &.ant-select-multiple {
      .ant-select-selector {
      }
      .ant-select-selection-overflow {
        height: 24px;
        display: inline-flex;
        flex-wrap: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        top: -2px;
        .ant-select-selection-overflow-item {
          margin: 0 4px 4px 0;
          .ant-select-selection-item {
            margin: 0;
            .ant-select-selection-item-content {
              font-size: 12px;
            }
          }
        }
      }
      .ant-select-selection-search {
        margin: 0;
      }
      .ant-select-selection-placeholder {
        left: 0;
      }
    }
    .ant-select-selection-placeholder,
    .ant-select-selection-item {
      line-height: 24px;
    }
  }
  .ant-input-prefix {
    color: #a0a0a0;
  }
  .ant-input-affix-wrapper,
  input,
  textarea {
    border: 0;
    height: auto;
    line-height: 25px;
    padding: 0;
    box-shadow: none !important;
    outline: none;
    &[disabled] {
      background-color: transparent;
    }
  }
  .ant-input-group-addon button {
    padding: 0;
    line-height: 1;
    border: none;
    height: auto;
  }
  .ant-picker {
    width: 100%;
    border: 0;
    box-shadow: none;
    outline: none;
    margin-left: -11px;
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
  }
  .StripeElement {
    padding: 2px 0 1px;
  }
  & + .error-text {
    display: none;
  }
  &.field-error-item {
    border-color: red;
    span {
      color: red;
    }
    & + .error-text {
      display: block;
      color: red;
      font-size: 12px;
      margin-bottom: 0;
      margin-top: 8px;
    }
  }
  &.field-required {
    & > span:first-child {
      &:after {
        display: inline-block;
        margin-left: 8px;
        width: 12px;
        height: 12px;
        line-height: 12px;
        content: "*";
        color: #ff4d4f;
      }
    }
  }
  @media @mobile {
    width: 100%;
  }
}
.apply-wrap {
}
button {
  &.ant-btn {
    height: 48px;
    color: #000;
    border: 1px solid #a0a0a0;
    padding: 0 24px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1px;
  }
  &.ant-btn-text {
    border: 0;
  }
  &.ant-btn-white {
    background-color: #000;
    color: #fff;
    border: 0;
    box-shadow: 6px 4px 14px rgba(0, 0, 0, 0.24);
    &:hover {
      color: #fff;
      background-color: #313335;
    }
  }
  &.ant-btn-primary,
  &.ant-btn-primary:active,
  &.ant-btn-primary:focus {
    background-color: #000;
    color: #00ff6d;
    border: 0;
    box-shadow: 6px 4px 14px rgba(0, 0, 0, 0.24);
    &:hover {
      color: #00ff6d;
      background-color: #313335;
    }
  }
  &.ant-btn[disabled],
  &.ant-btn[disabled]:hover {
    border: 1px solid #dddfe4 !important;
    background-color: #fff !important;
    color: #dddfe4 !important;
    &.ant-btn-primary {
      background-color: #dddfe4 !important;
      color: #f1f1f6 !important;
    }
  }
}

.ant-dropdown-link {
  cursor: pointer;
}

.table-item {
  .ant-table {
    background: #ffffff;
    border: 1px solid #dcdcdc;
    .ant-table-title,
    .ant-table-footer {
      font-family: RobotoBold;
      color: #000;
      font-weight: 900;
      border-bottom: 1px solid #f0f0f0;
      background-color: #fff;
      .footer-item {
        margin-bottom: 0;
        span {
          i {
            width: 100px;
            padding: 0;
            text-align: right;
            &:last-child {
              width: 80px;
            }
          }
        }
      }
    }

    .ant-table-thead > tr > th {
      background-color: #fff;
      text-transform: uppercase;
      color: #a0a0a0;
      font-weight: bolder;
      font-size: 12px;
      letter-spacing: 1px;
    }
    .ant-table-tbody > .ant-table-cell {
      color: #696969;
    }
    .ant-table-title {
      .ant-input-search {
        width: 200px;
        height: 26px;
        border: none;
        input {
          border: 0;
          width: 160px;
          box-shadow: none;
          padding: 0 0 0 11px;
          height: 26px;
          line-height: 26px;
          font-weight: normal;
          font-family: Roboto;
          color: #000;
          text-align: right;
          &::placeholder {
            color: #a0a0a0;
            text-align: right;
          }
        }
        button {
          border: 0;
          height: 26px;
          line-height: 32px;
          box-shadow: none;
          padding: 0;
        }
      }
    }
    .ellipsis-custom-cell {
      max-width: 200px;
    }
  }
}

.table-search-wrap {
  &.flex {
    font-family: RobotoBold;
    color: #000;
    font-weight: 900;
    border-bottom: 1px solid #f0f0f0;
    background-color: #fff;
    height: 58px;
    line-height: 58px;
    padding: 0 32px;
    @media @mobile {
      padding: 0 16px / @pv;
    }
    .ant-input-search {
      width: 200px;
      height: 26px;
      border: none;
      input {
        border: 0;
        width: 160px;
        box-shadow: none;
        padding: 0 0 0 11px;
        height: 26px;
        line-height: 26px;
        font-weight: normal;
        font-family: Roboto;
        color: #000;
        text-align: right;
        &::placeholder {
          color: #a0a0a0;
          text-align: right;
        }
      }
      button {
        border: 0;
        height: 26px;
        line-height: 32px;
        box-shadow: none;
        padding: 0;
      }
    }
  }
}

.flex {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  &.wrap {
    flex-wrap: wrap;
  }
  &.column {
    flex-direction: column;
  }
  &.start {
    justify-content: flex-start;
  }
  &.end {
    justify-content: flex-end;
  }
  &.between {
    justify-content: space-between;
  }
  &.around {
    justify-content: space-around;
  }
  &.align-end {
    align-items: flex-end;
  }
  &.align-start {
    align-items: flex-start;
  }
  &.reverse {
    flex-direction: row-reverse;
  }
  & > .flex-1,
  & > .ant-form-item {
    flex: 1;
    @media @mobile {
    }
  }
  & > .ant-form-item {
    &:not(:first-child) {
      margin-left: 24px;
    }
  }
}

.common-container {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
}

.common-virtual-table-container {
  .ant-list-header,
  .ant-list-footer {
    width: 100%;
    font-size: 12px;
    color: #a0a0a0;
    text-transform: uppercase;
    overflow: hidden;
    padding-left: 32px;
    .flex {
      width: 100%;
      span {
        flex: 1;
        min-width: 80px;
        &.table-cell-action,
        &:last-child {
          box-sizing: content-box;
          padding-right: 32px;
        }
      }
    }
  }
  .ant-list-header {
    height: 60px;
    padding: 0 0 0 32px;
    display: flex;
    align-items: center;
  }
  .ant-list-footer {
    border-top: 1px solid #e0e0e0;
    .th-cell {
      font-size: 16px;
      color: #000c17;
      font-family: RobotoBold;
      font-weight: 700;
      text-transform: initial;
    }
  }
  .ant-list-item {
    width: 100%;
    padding-left: 32px;
    border-bottom-color: #e0e0e0;
    &:last-child {
      border-bottom: 0;
    }
    .flex {
      width: 100%;
      .table-cell {
        flex: 1;
        min-width: 80px;
        font-size: 14px;
        color: #696969;
        padding: 0 8px 0 0;
        .fund-card-name {
          word-break: break-word;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        &.table-cell-action,
        &:last-child {
          box-sizing: content-box;
          padding-right: 32px;
        }
      }
    }
  }
  .ant-empty-normal {
    margin: calc(25% - 39px) 0;
  }
}

.table-container {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  width: 100%;
  box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.08);
  .ant-table {
    .ant-table-title,
    .ant-table-footer {
      font-family: RobotoBold;
      color: #000;
      font-weight: 900;
      border-bottom: 1px solid #f0f0f0;
      background-color: #fff;
      padding-right: 32px;
      .footer-item {
        margin-bottom: 0;
        span {
          i {
            width: 100px;
            padding: 0;
            text-align: right;
            &:last-child {
              width: 80px;
            }
          }
        }
      }
    }
    .ant-table-header {
      &.ant-table-sticky-holder,
      &.ant-table-sticky-header {
        position: sticky;
        z-index: 3;
      }
    }
    .ant-table-container {
      .ant-table-sticky-scroll {
        display: none;
      }
    }
    .ant-table-selection-column {
      padding-left: 16px;
    }
    .ant-table-thead > tr > th {
      background-color: #fff;
      text-transform: uppercase;
      color: #a0a0a0;
      font-weight: bolder;
      font-size: 12px;
      letter-spacing: 1px;
    }
    .ant-table-tbody > .ant-table-cell {
      color: #696969;
    }
    .ant-table-title {
      .ant-input-search {
        width: 200px;
        height: 26px;
        border: none;
        input {
          border: 0;
          width: 160px;
          box-shadow: none;
          padding: 0 0 0 11px;
          height: 26px;
          line-height: 26px;
          font-weight: normal;
          font-family: Roboto;
          color: #000;
          text-align: right;
          &::placeholder {
            color: #a0a0a0;
            text-align: right;
          }
        }
        button {
          border: 0;
          height: 26px;
          line-height: 32px;
          box-shadow: none;
          padding: 0;
        }
      }
    }
    .ellipsis-custom-cell {
      max-width: 200px;
    }
  }
  .ant-pagination {
    padding-right: 32px;
  }
}

.ant-dropdown {
  .ant-menu {
    box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.16),
      4px 4px 8px rgba(0, 0, 0, 0.04) !important;
  }
}

.ant-breadcrumb {
  margin-bottom: 40px;
  .ant-breadcrumb-link {
    display: inline-block;
    border: 1px solid #e0e0e0;
    padding: 4px 16px;
    font-size: 14px;
    font-weight: 700;
  }
  a.ant-breadcrumb-link {
    background-color: #fff;
    font-weight: normal;
  }
}

//modal

.stripe-pay-container iframe .CardBrandIcon-container {
  right: 8px !important;
  z-index: 100000 !important;
  &.is-hidden {
    img {
      opacity: 1 !important;
    }
  }
}
.common-modal {
  .ant-modal-body {
    max-height: 500px;
    overflow-y: auto;
  }
  .ant-modal-header {
    padding: 24px;
  }
  .tips-item {
    font-size: 16px;
    line-height: 26px;
    color: #4a4a4a;
    margin-bottom: 24px;
  }
  .ant-modal-title {
    font-size: 24px;
    font-weight: 900;
    font-family: RobotoBold;
    .title-wrap {
      .anticon {
        margin-left: 8px;
        font-size: 16px;
        color: #696969;
      }
    }
  }
  .field-form-items {
    width: 100%;
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
  .flex {
    & > .ant-form-item {
      flex: 1;
      &:not(:first-child) {
        margin-left: 16px;
      }
    }
  }
  @media @mobile {
    .ant-modal-footer {
      text-align: right !important;
      button {
        width: auto !important;
      }
    }
  }
}

.common-form {
  margin-bottom: 24px;
  &.shadow {
    background: #ffffff;
    border: 1px solid #e0e0e0;
    box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.08);
    padding: 32px;
  }
  .flex {
    & > .ant-form-item {
      flex: 1;
      &:not(:first-child) {
        margin-left: 24px;
      }
    }
  }
}

.common-drawer {
  .ant-drawer-header {
    padding-top: 32px;
    padding-bottom: 0;
    .ant-drawer-title {
      font-weight: 700;
      font-family: Roboto;
      font-size: 24px;
      letter-spacing: 0.5px;
    }
    button {
      top: 16px;
    }
    border-bottom: 0;
  }
  .ant-drawer-body {
    button:not(:first-child) {
      margin-left: 16px;
    }
    .ant-select.ant-select-multiple {
      height: auto !important;
      .ant-select-selector {
        &,
        .ant-select-selection-overflow {
          height: auto !important;
        }
      }
    }
  }
}

.sponsorship-modal {
  button {
    height: 42px;
    border: 1px solid #a0a0a0;
    color: #000;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
    padding: 0 24px;
    &.ant-btn-primary {
      border-color: #000;
      background: #000000;
      color: #00ff6d;
      box-shadow: 6px 4px 14px rgba(0, 0, 0, 0.24);
    }
  }
  @media @mobile {
    width: 80% !important;
    .ant-modal-footer {
      padding: 16px / @pv;
      button {
        width: auto !important;
      }
    }
  }
}

.mt-24 {
  margin-top: 24px;
}
.mb-24 {
  margin-bottom: 24px;
}

.text-12 {
  font-size: 12px;
  color: #a0a0a0;
}

.hidden {
  display: none;
}
.show {
  display: block;
}

.global-spin-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  background-color: rgba(255, 255, 255, 0.88);
  display: flex;
  justify-content: center;
  align-items: center;
}
.i-block-item {
  display: block;
  width: 100%;
  margin-bottom: 8px;
  line-height: 28px;
}

.ant-tooltip {
  .ant-tooltip-arrow-content {
    background-color: white;
  }
  .ant-tooltip-inner {
    background-color: white;
    color: #333;
  }
}

.mobile-header-menu {
  position: fixed;
  top: 72px;
  right: 0;
  width: 100vw;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #005450;
  color: #fff;
  .ant-menu-item {
    height: 56px;
    line-height: 56px;
    a {
      color: #66c4a6 !important;
    }
    &.ant-menu-item-only-child {
      padding-left: 30px !important;
      a {
        color: #fff !important;
      }
    }
  }

  .ant-menu-submenu-title {
    padding-left: 30px !important;
    height: 56px !important;
    line-height: 56px !important;
    &:active {
      background: transparent;
    }
  }
  .ant-menu-sub.ant-menu-inline {
    background-color: #0d4745;
  }
  .ant-menu-item-selected,
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: transparent;
    color: #1da57a;
  }
  .ant-menu-submenu-open {
    & > ul.ant-menu-sub {
      & > li.ant-menu-item {
        padding-left: 48px !important;
      }
      & > li.ant-menu-submenu {
        padding-left: 48px !important;
        .ant-menu-submenu-title {
          color: #fff;
          padding-left: 0 !important;
        }
        & > ul.ant-menu-sub {
          background-color: #153f3d;
          margin-left: -48px !important;
          padding-left: 48px !important;
          width: 100vw;
          & > li.ant-menu-item {
            padding-left: 24px !important;
          }
        }
      }
    }
  }
}

.app-container {
  .mobile-dashboard-empty {
    width: 100vw;
    text-align: center;
    img {
      width: 128px / @pv;
      height: 128px / @pv;
      display: inline-block;
      margin-top: 60px / @pv;
    }
    h1 {
      margin-top: 105px / @pv;
    }
    .des {
      padding: 30px / @pv;
    }
  }
}

.antd-img-crop-modal {
  .img-crop-button-container {
    padding-top: 24px;
    button {
      height: auto;
      padding: 0;
      margin-right: 24px;
      border: none;
      color: #1da57a;
    }
  }
}

.ant-modal {
  .ant-modal-footer {
    @media @mobile {
      text-align: center;
      button.ant-btn {
        width: 45%;
      }
    }
  }
  &.warning-modal {
    .info-container {
      padding-bottom: 8px;
      .title-wrapper {
        width: 100%;
        .anticon {
          margin-right: 16px;
          font-size: 22px;
          color: #faad14;
        }
        overflow: hidden;
        color: #000000d9;
        font-weight: 500;
        font-size: 16px;
        line-height: 1.4;
      }
      .modal-content-wrapper {
        text-align: left;
        padding-left: 36px;
      }
    }
  }
}

.plyr--full-ui .plyr__video-embed > .plyr__video-embed__container {
  //@media @desktop {
  transform: translateY(-38.28125%) !important;
  //}
}

.info-container {
  text-align: center;
  img {
    height: 130px;
    margin-bottom: 36px;
    margin-top: 64px;
  }
  h4 {
    font-size: 32px;
    line-height: 42px;
  }
  p {
    color: #696969;
  }
  @media @mobile {
    img {
      margin-top: 16px / @pv;
    }
    h1 {
      line-height: 1.4;
    }
    p {
      font-size: 16px / @pv;
    }
    .field-form-items {
      i {
        flex: 0 0 60%;
      }
    }
  }
}

.field-form-items.required {
  & > span:first-child {
    &:after {
      display: inline-block;
      width: 12px;
      height: 12px;
      line-height: 12px;
      content: "*";
      color: #ff4d4f;
    }
  }
}
.common-container {
  position: relative;
  .ant-table-pagination.ant-pagination {
    margin: 16px;
  }
}
.menu-layout-container {
  .ant-menu {
    .ant-menu-item,
    .ant-menu-submenu {
      font-size: 16px;
      &.ant-menu-item-selected {
        background-color: #dfffed;
        color: black;
        font-weight: bold;
      }
    }
  }
}

.ant-picker-range .ant-picker-clear {
  opacity: 1;
}
.ant-select-allow-clear .ant-select-clear {
  opacity: 1;
}
