@import "@/styles/theme.less";
.admin-consultation-staff-list-container {
  .search-container {
    .filter-item {
      width: 56px;
      height: 56px;
      flex: 0 0 56px;
      border: 1px solid #e0e0e0;
      margin-left: 16px;
      cursor: pointer;
    }
  }

  .field-form-items {
    overflow: hidden;
  }
  .tf-autocomplete {
    .ant-select-selection-placeholder,
    .ant-select-selector::after {
      line-height: 25px;
    }
    .ant-select-selector {
      border: none;
      outline: none;
      box-shadow: none !important;
    }
    .ant-select-selection-search {
      left: 0px;
    }
  }

  .ant-picker-clear svg {
    margin-left: -30px;
  }

  .custom-select-render.filter-by-admin {
    .ant-select-selector {
      padding-left: 147px !important;
    }
  }

  .custom-select-render.filter-by-duration {
    .ant-select-selector {
      padding-left: 117px !important;
    }
  }

  .custom-select-render.filter-by-topic {
    .ant-select-selector {
      padding-left: 98px !important;
    }
  }

  .custom-select-render.filter-by-status {
    .ant-select-selector {
      padding-left: 100px !important;
    }
  }

  .filter-item {
    height: 32px;
    width: 32px;
    border: 1px solid #e0e0e0;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-pagination-total-text {
    position: absolute;
    left: 32px;
    color: #000;
    font-weight: 700;
  }
}

.admin-consultation-staff-menu {
  .ant-dropdown-menu-item {
    padding: 15px 30px 15px 24px;
  }
}
