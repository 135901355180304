.my-message-con {
    display   : flex;
    height    : 100%;
    background: #fff;

    .avatar {
        width          : 24px;
        height         : 24px;
        background     : #D7DEE4;
        border-radius  : 50%;
        left           : 0px;
        top            : -18px;
        display        : flex;
        justify-content: center;
        align-items    : center;

        span {
            font-weight: 700;
            color      : rgba(45, 46, 61, 0.5);
        }
    }

    .my-channels {
        min-width: 300px;
        flex        : 1;
        border-right: 1px solid #EAEAEC;
        max-width: 32%;
        height: 99vh;
        overflow-y: auto;
        .name-filter {
            height: 54px;
        }
        .channel {
            width        : 100%;
            display      : flex;
            padding      : 18px 23PX;
            align-items  : center;
            border-bottom: 1px solid #EAEAEC;
            cursor: pointer;
            &.selected {
                background: #DFFFED;
                .summary {
                    .title {
                        color: #000;
                        font-weight: bold;
                    }
                }
            }
            .avatar {
                width : 24px;
                height: 24px;
                flex-shrink: 0;
            }

            .summary {
                flex       : 1;
                margin-left: 16px;
                color: #313335;
                width: 80%;
                position: relative;
                .title{
                    font-size: 16px;
                }
                .content {
                    display: flex;
                    width: 100%;
                    font-size: 14px;
                    font-weight: bold;
                    .text {
                        width: 100%;
                        flex: 1;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    .time {
                        width          : 80px;
                        flex-shrink    : 0;
                        display        : flex;
                        justify-content: flex-end;
                        align-items    : flex-end;
                    }
                }

                &.unread {
                    color: #2D2E3D;
                }

                .red-dot {
                    display: inline-block;
                    width: 12px;
                    height: 12px;
                    background: red;
                    border-radius: 50%;
                    position: absolute;
                    top: 6px;
                    right: 20px;
                    margin-left: 8px;
                  }
                
            }

        }
    }

    .my-message {
        flex          : 2;
        display       : flex;
        flex-direction: column;
        position: relative;
        height        : 100%;
        .title {
            font-weight: 700;
            font-size  : 25px;
            color      : #2D2E3D;
            height     : 40px;
            width      : 100%;
            flex       : 0;
            padding: 10px;
        }
        .auto-tip {
            position: absolute;
            top: 62px;
            left: 0;
            right: 0;
            padding: 8px 10px 6px;
            background: rgba(18, 156, 87, 0.6);
            color: #fff;
            visibility: hidden;
            opacity: 0;
            transition: all 1s ease-in;
            z-index: 3;
            h3{
                display: inline-block;
                color: #fff;
                margin: 0;
            }
            span {
                color: rgb(247, 250, 224);
                padding: 0 10px;
                &:nth-of-type(2) {
                    padding-left: 3.5em;
                }
            }
            i {
                text-decoration: underline;
                color: rgb(217, 255, 230);
                user-select: none;
                cursor: pointer;
            }
            &.show {
                visibility: visible;
                opacity: 1;
            }
        }
        .msg-panel {
            flex      : 2;
            background: #F1F1F6;
            padding       : 40px;

            overflow  : auto;
            font-size : 12px;
            
            .load-more,.timeline {
                text-align: center;
                position  : relative;
                margin    : 25px 0;

                span {
                    display   : inline-block;
                    padding   : 10px;
                    position  : relative;
                    z-index   : 1;
                    color     : #797F84;
                }

                &::before {
                    content      : '';
                    position     : absolute;
                    width        : 100%;
                    height       : 0;
                    top          : 50%;
                    left         : 0;
                    border-bottom: 1px solid #EAEAEC;
                }
            }
            .load-more {
                cursor: pointer;
                span {
                    color: rgb(0, 209, 157);
                }
            }

            .chat-msg {
                position: relative;
                padding : 0 32px;

                .avatar {
                    position: absolute;
                    left: 0;
                    top: unset;
                    bottom: 0px;
                    overflow: hidden;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .name {
                    position   : absolute;
                    top        : -16px;
                    left       : 42px;
                    font-weight: 700;
                    color      : #96979E;
                }

                .content {
                    background-color: #fff;
                    display         : inline-block;
                    margin-top      : 8px;
                    padding         : 10px 12px;
                    border-radius   : 6px;
                    line-height     : 1.5;
                    vertical-align  : baseline;
                    font-size       : 14px;
                    max-width       : 65%;

                    p,
                    span {
                        margin-top   : 0;
                        margin-bottom: 0;
                    }

                    a {
                        color: #2F80ED;
                    }
                }

                &.right {
                    text-align: right;
                    .avatar {
                        left: unset;
                        right: 0;
                    }
                    .content {
                        background-color: #DDDFE4;
                    }
                }
            }
        }

        .input-panel {
            flex      : 0;
            background: #fff;
            height    : 50px;
            display   : flex;
            margin-top: 10px;
            padding: 0 20px;

            .input-field,
            .attach,
            .send {
                height     : 42px;
                flex       : 0;
                margin-left: 15px;
            }

            .attach {
                padding: 0 10px;
                border : none;
            }

            .send {
                padding: 0 16px;
            }

            .input-field {
                flex       : 1;
            }
        }

        .attach-panel {
            height: 40px;
        }
    }
}