.donor-report-con {
    .search-container {
        &>* {
            margin: 0 8px;
            &:first-child{
                margin-left: 0;
            }
            &:last-child{
                margin-right: 0;
            }
        }
        .compare {
            min-width: 176px;
        }
        .ant-btn {
            height: 56px;
            padding: 0 32px;
        }
    }
    .section {
        margin: 36px 0;

        i{
            width: 32px;
        }
        .part {
            width: calc(50% - 16px);
            height: 380px;
        }
        .report-chart{
            background: white;
            padding: 12px 32px;
            width: 100%;
            .top{
                border-bottom: 1px solid #DDDFE4;
                margin-bottom: 12px;
                padding-bottom: 6px;
                .ant-btn{
                    padding: 0 12px;
                    height: 40px;
                }
            }
        }
        .circle-chart {
            width: calc(50% - 16px);
            height: 380px;
            .pie-con {
                padding-top: 10px;
                align-items: center;
                justify-content: center;
            }
            .fake-legend{
                margin: 30px 40px 0;
                .legend-item{
                    padding: 8px;
                    border-bottom: 1px solid #DDDFE4;
                    .mark {
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        margin-right: 10px;
                    }
                    .name {
                        flex: 1;
                    }
                    .value {
                        font-weight: bold;
                    }
                }
            }
        }
        &.section-bottom{
            .report-chart {
                max-width: calc(50% - 16px);;
                margin-bottom: 32px;
            }
        }
    }
}