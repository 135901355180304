.grant-application-list {
    padding: 50px 40px 30px;
    .topbar{
        .title {
            padding-left: 12px;
            font-size: 45px;
            font-weight: 900;
            display: inline-block;
            margin-right: auto;
        }
    }
    .filters {
        .filter-item {
            // max-width: 24%;
            width: 24%;
            &.ant-btn {
                flex: 0;
                margin-left: auto;
            }
           
        }
    }
    .statistics {
        border: 1px solid #DDDFE4;
        background-color: white;
        margin: 30px 0;
        padding: 24px 32px;

        h3 {
            font-size: 22px;
        }
        .num {
            margin: 0 auto 10px 20px;
        }
    }
}
