@import "../../styles/theme.less";

.admin-changepwd{  
  height: 100%;
  h1 {
    color: @primary-dark;
    font-size: 45px;
    font-weight: 900;
  }

  .section-changepwd {
    padding: 32px;
    margin-bottom: 24px;
    background: #fff;
    border: 1px solid #e0e0e0;
    box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.08);

    .ant-form{
      margin-left: 60px;
      width: 400px;
    }
    
  }

}