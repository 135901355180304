@import "../../../../styles/theme.less";
.contribution-container {
  .search-container {
    .filter-item {
      width: 56px;
      height: 56px;
      flex: 0 0 56px;
      border: 1px solid #e0e0e0;
      margin-left: 16px;
      cursor: pointer;
    }
    .clear-all {
      margin-left: 18px;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 900;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .data-chart-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 0;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.08);
    margin-bottom: 24px;
    span {
      flex: 1;
      padding-left: 32px;
      &:not(:last-child) {
        border-right: 1px solid #e0e0e0;
      }
      i {
        display: block;
        width: 100%;
        &:first-child {
          text-transform: uppercase;
          font-size: 12px;
          font-weight: 700;
          color: #a0a0a0;
          letter-spacing: 1px;
          margin-bottom: 8px;
        }
        &:last-child {
          font-family: RobotoBold;
          font-size: 32px;
          font-weight: 900;
          line-height: 42px;
        }
      }
    }
  }
  .ant-pagination-total-text {
    position: absolute;
    left: 32px;
    color: #000;
    font-weight: 700;
  }
}
