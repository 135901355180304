.admin-grant-list {
  padding: 50px 40px 30px;
  .topbar {
    margin-bottom: 0px;
    .title {
      padding-left: 12px;
      font-size: 45px;
      font-weight: 900;
      display: inline-block;
      margin-right: auto;
    }
    .ant-btn {
      margin: 4px 10px;
      &:hover,
      &:focus {
        background-color: #d7dee4;
        border-radius: 4px;
        color: #2d2e3d;
      }
    }
  }
  .filters {
    margin-bottom: 24px;
    .filter-item {
      flex: 1;
      margin-left: 16px;
      &:first-child {
        margin-left: 0;
      }
      &.ant-dropdown-link {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 56px;
        height: 56px;
        max-width: 56px;
        border: 1px solid #dddfe4;
      }
      .ant-select .ant-select-selection-overflow {
        max-width: 200px;
        flex-wrap: nowrap;
        overflow: hidden;
      }
    }
  }
}
