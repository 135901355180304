/* @tailwind base; */

.pointer-events-none {
  pointer-events: none
}

.visible {
  visibility: visible
}

.fixed {
  position: fixed
}

.absolute {
  position: absolute
}

.relative {
  position: relative
}

.sticky {
  position: sticky
}

.bottom-0 {
  bottom: 0px
}

.left-2 {
  left: 0.5rem
}

.right-0 {
  right: 0px
}

.top-2 {
  top: 0.5rem
}

.top-\[1px\] {
  top: 1px
}

.z-10 {
  z-index: 10
}

.m-0 {
  margin: 0px
}

.my-12 {
  margin-top: 3rem;
  margin-bottom: 3rem
}

.my-7 {
  margin-top: 1.75rem;
  margin-bottom: 1.75rem
}

.my-\[2px\] {
  margin-top: 2px;
  margin-bottom: 2px
}

.\!mb-0 {
  margin-bottom: 0px !important
}

.\!mb-4 {
  margin-bottom: 1rem !important
}

.\!mt-10 {
  margin-top: 2.5rem !important
}

.\!mt-4 {
  margin-top: 1rem !important
}

.-mt-10 {
  margin-top: -2.5rem
}

.-mt-4 {
  margin-top: -1rem
}

.mb-12 {
  margin-bottom: 3rem
}

.mb-2 {
  margin-bottom: 0.5rem
}

.mb-20 {
  margin-bottom: 5rem
}

.mb-32 {
  margin-bottom: 8rem
}

.mb-4 {
  margin-bottom: 1rem
}

.mb-6 {
  margin-bottom: 1.5rem
}

.mb-8 {
  margin-bottom: 2rem
}

.mb-\[71px\] {
  margin-bottom: 71px
}

.mb-auto {
  margin-bottom: auto
}

.ml-10 {
  margin-left: 2.5rem
}

.ml-2 {
  margin-left: 0.5rem
}

.ml-4 {
  margin-left: 1rem
}

.ml-auto {
  margin-left: auto
}

.mr-2 {
  margin-right: 0.5rem
}

.mr-3 {
  margin-right: 0.75rem
}

.mr-4 {
  margin-right: 1rem
}

.mr-6 {
  margin-right: 1.5rem
}

.mt-1 {
  margin-top: 0.25rem
}

.mt-10 {
  margin-top: 2.5rem
}

.mt-24 {
  margin-top: 6rem
}

.mt-5 {
  margin-top: 1.25rem
}

.mt-6 {
  margin-top: 1.5rem
}

.mt-\[22px\] {
  margin-top: 22px
}

.mt-\[60px\] {
  margin-top: 60px
}

.mt-auto {
  margin-top: auto
}

.\!block {
  display: block !important
}

.block {
  display: block
}

.flex {
  display: flex
}

.hidden {
  display: none
}

.size-14 {
  width: 3.5rem;
  height: 3.5rem
}

.size-6 {
  width: 1.5rem;
  height: 1.5rem
}

.size-\[10px\] {
  width: 10px;
  height: 10px
}

.\!h-auto {
  height: auto !important
}

.h-full {
  height: 100%
}

.h-screen {
  height: 100vh
}

.\!max-h-none {
  max-height: none !important
}

.min-h-96 {
  min-height: 24rem
}

.min-h-\[229px\] {
  min-height: 229px
}

.min-h-\[304px\] {
  min-height: 304px
}

.w-10\/12 {
  width: 83.333333%
}

.w-\[374px\] {
  width: 374px
}

.w-\[590px\] {
  width: 590px
}

.w-full {
  width: 100%
}

.w-screen {
  width: 100vw
}

.\!min-w-\[110px\] {
  min-width: 110px !important
}

.\!min-w-\[150px\] {
  min-width: 150px !important
}

.min-w-\[110px\] {
  min-width: 110px
}

.min-w-\[120px\] {
  min-width: 120px
}

.min-w-\[130px\] {
  min-width: 130px
}

.min-w-\[200px\] {
  min-width: 200px
}

.min-w-\[60px\] {
  min-width: 60px
}

.\!max-w-\[40vw\] {
  max-width: 40vw !important
}

.max-w-\[100vw\] {
  max-width: 100vw
}

.max-w-\[200px\] {
  max-width: 200px
}

.max-w-\[480px\] {
  max-width: 480px
}

.\!flex-auto {
  flex: 1 1 auto !important
}

.flex-auto {
  flex: 1 1 auto
}

.cursor-pointer {
  cursor: pointer
}

.flex-col {
  flex-direction: column
}

.\!flex-wrap {
  flex-wrap: wrap !important
}

.flex-wrap {
  flex-wrap: wrap
}

.items-start {
  align-items: flex-start
}

.items-center {
  align-items: center
}

.justify-start {
  justify-content: flex-start
}

.justify-center {
  justify-content: center
}

.justify-between {
  justify-content: space-between
}

.gap-2 {
  gap: 0.5rem
}

.gap-4 {
  gap: 1rem
}

.self-start {
  align-self: flex-start
}

.overflow-hidden {
  overflow: hidden
}

.text-ellipsis {
  text-overflow: ellipsis
}

.whitespace-nowrap {
  white-space: nowrap
}

.rounded {
  border-radius: 0.25rem
}

.\!border-b-0 {
  border-bottom-width: 0px !important
}

.border-none {
  border-style: none
}

.bg-\[\#10AC57\] {
  --tw-bg-opacity: 1;
  background-color: rgb(16 172 87 / var(--tw-bg-opacity))
}

.bg-\[\#797F84\] {
  --tw-bg-opacity: 1;
  background-color: rgb(121 127 132 / var(--tw-bg-opacity))
}

.bg-\[\#F1F1F6\] {
  --tw-bg-opacity: 1;
  background-color: rgb(241 241 246 / var(--tw-bg-opacity))
}

.bg-\[white\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity))
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity))
}

.object-cover {
  object-fit: cover
}

.p-12 {
  padding: 3rem
}

.p-2 {
  padding: 0.5rem
}

.p-4 {
  padding: 1rem
}

.p-6 {
  padding: 1.5rem
}

.p-8 {
  padding: 2rem
}

.\!py-5 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem
}

.px-\[26px\] {
  padding-left: 26px;
  padding-right: 26px
}

.py-0 {
  padding-top: 0px;
  padding-bottom: 0px
}

.py-40 {
  padding-top: 10rem;
  padding-bottom: 10rem
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem
}

.py-\[32px\] {
  padding-top: 32px;
  padding-bottom: 32px
}

.\!pl-8 {
  padding-left: 2rem !important
}

.pl-4 {
  padding-left: 1rem
}

.pr-4 {
  padding-right: 1rem
}

.text-center {
  text-align: center
}

.font-roboto {
  font-family: Roboto, sans-serif
}

.text-\[12px\] {
  font-size: 12px
}

.text-\[14px\] {
  font-size: 14px
}

.text-\[16px\] {
  font-size: 16px
}

.text-\[24px\] {
  font-size: 24px
}

.text-\[32px\] {
  font-size: 32px
}

.text-\[40px\] {
  font-size: 40px
}

.font-\[900\] {
  font-weight: 900
}

.font-bold {
  font-weight: 700
}

.font-extrabold {
  font-weight: 800
}

.font-normal {
  font-weight: 400
}

.normal-case {
  text-transform: none
}

.leading-\[1\.58\] {
  line-height: 1.58
}

.leading-\[1\.5\] {
  line-height: 1.5
}

.leading-\[30px\] {
  line-height: 30px
}

.leading-\[38px\] {
  line-height: 38px
}

.leading-\[50px\] {
  line-height: 50px
}

.text-\[\#304659\] {
  --tw-text-opacity: 1;
  color: rgb(48 70 89 / var(--tw-text-opacity))
}

.text-\[\#4A4A4A\] {
  --tw-text-opacity: 1;
  color: rgb(74 74 74 / var(--tw-text-opacity))
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity))
}

.underline {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline
}

.shadow {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.filter {
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
          filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.duration-200 {
  transition-duration: 200ms
}

.hover\:bg-black\/10:hover {
  background-color: rgb(0 0 0 / 0.1)
}

.hover\:bg-black\/5:hover {
  background-color: rgb(0 0 0 / 0.05)
}
