@import "@/styles/theme.less";
.admin-consultation-topic-list-container {
  .search-container {
    .filter-item {
      width: 56px;
      height: 56px;
      flex: 0 0 56px;
      border: 1px solid #e0e0e0;
      margin-left: 16px;
      cursor: pointer;
    }
  }

  .field-form-items {
    overflow: hidden;
  }

  .row-dragging {
    background: #fafafa;
    border: 1px solid #ccc;
  }

  .row-dragging td {
    padding: 16px;
  }
}

.add-consultant-topic-modal {
  .ant-modal-footer {
    padding: 16px 24px;
    .ant-btn {
      margin-left: 16px !important;
    }
  }

  .ant-form-item-label
    > label.ant-form-item-required:not(
      .ant-form-item-required-mark-optional
    )::before {
    display: none;
  }

  .ant-form .ant-form-item .ant-form-item-control-input textarea.ant-input {
    min-height: 104px;
  }

  .ant-input-number-input-wrap {
    border: none;
  }
}

.admin-consultation-topic-menu {
  .ant-dropdown-menu-item {
    padding: 15px 30px 15px 24px;
  }
}
