@import "../theme";
.artist-donation-container {
  .step-container {
    width: 580px;
    text-align: center;
    .ant-steps-item
      > .ant-steps-item-container
      > .ant-steps-item-content
      > .ant-steps-item-description {
      font-size: 12px;
    }
    .ant-steps-dot .ant-steps-item-content {
      width: 148px;
    }
    img {
      width: 130px;
      height: 130px;
      margin-bottom: 40px;
    }
    @media @mobile {
      width: 100%;
      padding: 40px / @pv 0 0;
      h2 {
        margin-bottom: 24px / @pv !important;
      }
      .ant-steps {
        display: none;
      }
    }
  }
  .campaign-container {
    width: 100%;
    .range-picker-wrap {
      margin-bottom: 24px;
    }
    .campaign-on-wrap {
      background: #ffffff;
      border: 1px solid #e0e0e0;
      box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.08);
      h4.title {
        padding: 0 32px;
        margin-bottom: 0;
        height: 58px;
        line-height: 58px;
        border-bottom: 1px solid #e0e0e0;
        font-weight: bolder;
        font-family: RobotoBold;
      }
      .campaign-list {
        padding: 32px;
        &:not(:last-child) {
          border-bottom: 1px solid #e0e0e0;
        }
        .flex {
          width: 100%;
        }
        h2 {
          margin-bottom: 0;
        }
        img {
          width: 120px;
          height: 120px;
          margin-right: 24px;
          object-fit: cover;
        }
        .campaign-info-wrap {
          .info-title {
            & > span {
              font-size: 12px;
              color: #a0a0a0;
              flex: 0 0 248px;
              white-space: normal;
              text-align: right;
            }
          }
          .ant-tag {
            margin-left: 8px;
          }
        }
        ul {
          margin-bottom: 0;
          padding-left: 20px;
          color: #a0a0a0;
          li {
            span {
              display: inline-block;
              width: 68px;
            }
          }
        }
        .btn {
          flex: 0 0 200px;
        }
        .ant-dropdown-link {
          width: 48px;
          line-height: 48px;
          height: 48px;
          margin-right: 16px;
          border: 1px solid #000;
          display: inline-block;
          font-size: 26px;
          color: #000;
          text-align: center;
        }
        @media @mobile {
          padding: 16px / @pv;
          h4.title {
            padding: 0 16px / @pv;
          }
          & > .flex.start {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            img {
              width: 100%;
              height: 120px;
              object-fit: cover;
              object-position: center;
            }
          }
          .campaign-info-wrap {
            .info-title {
              flex-direction: column;
              align-items: flex-start;
              h2 {
                flex-direction: column-reverse;
                justify-content: flex-start;
                align-items: flex-start;
                span {
                  margin: 24px / @pv 0 0 0;
                }
              }
              span {
                margin-top: 4px / @pv;
                flex: 0 0 auto;
              }
            }
            .flex.align-end {
              flex-direction: column;
              justify-content: flex-start;
              align-items: flex-start;
              ul {
                margin-top: 16px / @pv;
              }
              .btn {
                //margin: 18px / @pv -16px / @pv 0;
                padding: 24px / @pv 0 0;
                flex: 0 0 auto;
                width: 100%;
                //border-top: 1px solid #dddfe4;
              }
            }
          }
        }
      }
    }
    @media @mobile {
      h1.flex {
        flex-direction: column;
        align-items: flex-start;
        button {
          margin-top: 24px / @pv;
        }
      }
    }
  }
}

.campaign-create-container,
.campaign-detail-container {
  .ant-breadcrumb {
    margin-bottom: 40px;
    .ant-breadcrumb-link {
      display: inline-block;
      border: 1px solid #e0e0e0;
      padding: 4px 16px;
      font-size: 14px;
      font-weight: 700;
    }
    a.ant-breadcrumb-link {
      font-weight: normal;
      @media @mobile {
        &:not(:last-child) {
          margin-bottom: 8px / @pv;
        }
      }
    }
  }
  h1 {
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 16px;
  }
  h4 {
    width: 60%;
    margin-bottom: 40px;
    color: #4a4a4a;
    @media @mobile {
      width: 100%;
    }
  }
  .campaign-form {
    width: 100%;
    padding: 32px;
    background: #ffffff;
    border: 1px solid #e0e0e0;
    box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.08);
    margin-bottom: 24px;
    @media @mobile {
      padding: 16px / @pv;
    }
    h2 {
    }
    .ant-upload {
      width: 100%;
    }
    .upload-item-wrap {
      margin-bottom: 0;
    }
  }
  .btn {
    button {
      margin-left: 16px;
    }
  }
  .table-wrap {
    .table-item {
      padding: 0;
      position: relative;
      background-color: #fff;
      border: 1px solid #e0e0e0;
      box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.08);
      .common-virtual-table-container {
        .ant-empty-normal {
          margin: 50px 0;
        }
      }
    }
  }
}

.campaign-detail-container {
  .crow-wrap {
    background: #ffffff;
    border: 1px solid #e0e0e0;
    box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.08);
    width: 100%;
    h4 {
      width: 100%;
      padding: 16px 32px;
      margin-bottom: 0 !important;
      font-weight: 900;
      border-bottom: 1px solid #e0e0e0;
    }
    .crow-info {
      padding: 32px;
      p {
        margin-bottom: 8px;
        span {
          text-transform: uppercase;
          color: #a0a0a0;
          letter-spacing: 1px;
        }
      }
      h2 {
        margin-bottom: 0 !important;
        font-size: 32px;
        i {
          font-size: 16px;
          color: #aaa;
          font-weight: normal;
        }
      }
      .ant-progress {
        margin-bottom: 16px;
        .ant-progress-inner {
          border-radius: 0;
        }
        .ant-progress-bg {
          height: 24px !important;
          border-radius: 0 !important;
          background-color: #00ff6d;
        }
      }
    }
  }
  .table-wrap {
    width: 100%;
    .search-container {
      justify-content: flex-end;
      width: 100%;
    }
  }
  .ant-divider {
    margin: 40px 0;
  }
  @media @mobile {
    .ant-breadcrumb {
      margin-bottom: 16px / @pv;
    }
    .crow-wrap {
      h4 {
        padding: 8px / @pv 16px / @pv;
        font-weight: 900 !important;
      }
      .crow-info {
        padding: 32px / @pv 16px / @pv 16px / @pv;
        & > .flex {
          color: #797f84;
          font-size: 12px / @pv;
          margin-bottom: 8px / @pv;
          h4 {
            padding: 0 4px / @pv 0 0;
            font-size: 16px / @pv;
            width: auto !important;
            font-weight: 900;
            border-bottom: 0;
          }
        }
        & > p {
          font-size: 12px / @pv;
        }
      }
    }
    .table-wrap {
      .table-item {
        border: 0;
      }
    }
  }
}

.stats-container {
  .range-picker-wrap {
    margin-bottom: 24px;
  }
  .chart-wrapper {
    width: 100%;
    @media @mobile {
      height: auto;
      flex-flow: column;
      .chart-wrap {
        width: 100%;
        &:not(:last-child) {
          margin-bottom: 16px / @pv;
        }
      }
    }
  }
  .chart-wrap {
    flex: 0 0 49%;
    height: 286px;
    padding: 0;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.08);
    h4 {
      font-family: RobotoBold;
      font-weight: 900;
      color: #000;
      height: 58px;
      line-height: 58px !important;
      padding: 0 32px;
      margin-bottom: 0 !important;
      border-bottom: 1px solid #e0e0e0;
    }
  }
  .no-data {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80%;
    svg {
      width: 32px;
      height: 32px;
      color: #e0e0e0;
      margin-bottom: 16px;
    }
    h4 {
      margin-bottom: 6px !important;
      color: #000;
      font-weight: bolder;
      font-size: 14px;
      line-height: 24px;
      border: none;
      height: 24px;
    }
    p {
      margin-bottom: 0;
      color: #a0a0a0;
      font-size: 12px;
    }
    a {
      margin-top: 16px;
      color: #000;
      text-transform: uppercase;
      font-size: 12px;
      text-decoration: underline;
      font-weight: 900;
      letter-spacing: 1px;
    }
    @media @mobile {
      padding: 32px / @pv 0;
    }
  }
  .pie-wrap {
    margin-right: 1%;
    position: relative;
    @media @mobile {
      height: 320px;
    }
    .g2-html-annotation {
      &:last-child {
        font-family: RobotoBold;
        letter-spacing: 1px;
      }
    }
    .data-legend {
      position: absolute;
      right: 0;
    }
  }
  .crow-wrap {
    .crowdfunding-info {
      padding: 32px;
      h2 {
        margin-bottom: 20px !important;
        i {
          font-family: Roboto;
          font-weight: normal;
          font-size: 16px;
          color: #a0a0a0;
        }
        &:nth-of-type(2) {
          margin-bottom: 0 !important;
        }
      }
    }
    .ant-progress-inner {
      border-radius: 0px;
      .ant-progress-bg {
        height: 20px !important;
        background-color: #00ff6d;
      }
    }
    p {
      margin-top: 18px;
      margin-bottom: 0;
      font-size: 14px;
      color: #a0a0a0;
      b {
        font-weight: bolder;
        color: #000;
      }
    }
    .no-data {
      p {
        padding: 0 32px;
        text-align: center;
      }
    }
  }
  .ant-divider {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .search-container {
    svg {
      margin-left: 0;
    }
  }
  .table-wrap {
    width: 100%;
    & > .flex.start {
      & > button {
        height: 54px;
        margin-bottom: 24px;
        margin-left: 16px;
      }
      & > .search-container {
        justify-content: flex-end;
        width: 100%;
      }
    }
    .table-item {
      padding: 0;
      position: relative;
      background-color: #fff;
      border: 1px solid #e0e0e0;
      box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.08);
      .common-virtual-table-container {
        .ant-empty-normal {
          margin: 50px 0;
        }
      }
      &.kind-table-item {
        .ant-table {
          border: 0;
        }
        .ant-pagination {
          margin-right: 24px;
        }
      }
    }
    @media @mobile {
      width: 100%;
      overflow: hidden;
      .ant-list {
        overflow-y: auto;
      }
    }
  }
}

.fund-raise-container {
  .table-wrap {
    width: 100%;
  }
  .title.flex {
    @media @mobile {
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      margin-bottom: 24px / @pv;
      & > .flex {
        width: 100%;
      }
    }
  }
  .ant-table-title {
    .table-item {
    }
  }
  .table-item {
    padding: 0;
    position: relative;
    //background-color: #fff;
    //box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.08);
    @media @desktop {
      //border: 1px solid #e0e0e0;
    }
    @media @mobile {
      .ant-pagination {
        margin-right: 24px;
      }
    }
  }
}
.general-fund-container {
  .ant-table-footer {
    .footer-item {
      .flex {
        i {
          &:nth-of-type(1) {
            width: 145px !important;
          }

          &:nth-of-type(2) {
            width: 90px !important;
          }
          &:nth-of-type(3) {
            width: 155px !important;
          }
        }
      }
    }
  }
  .table-wrap {
    .title.flex {
      @media @mobile {
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        margin-bottom: 24px / @pv;
        & > .flex {
          width: 100%;
        }
      }
    }
    .table-item {
      padding: 0;
      position: relative;
      background-color: #fff;
      box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.08);
      @media @desktop {
        border: 1px solid #e0e0e0;
      }
      @media @mobile {
        .ant-pagination {
          margin-right: 24px;
        }
      }
    }
  }
}

.donation-detail-container {
  h1 {
    margin-bottom: 40px !important;
    & > span {
      border: 1px solid #e0e0e0;
      height: 56px;
      width: 56px;
      line-height: 48px;
      text-align: center;
      background-color: #fff;
      cursor: pointer;
      a {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.preview-btn {
  width: calc(100% - 280px);
  @media @mobile {
    width: 100%;
  }
  padding: 0 32px;
  margin: 0 140px;
  height: 64px;
  line-height: 64px;
  background-color: #e0e0e0;
  button {
    margin-left: 16px;
    &.ant-btn-text {
      span{
        text-decoration: underline;
      }
    }
    @media @mobile {
      margin-left: 6px / @pv;
    }
  }
  @media @mobile {
    width: 100%;
    padding: 0 16px / @pv;
    & > span {
      text-transform: capitalize;
      i {
        display: none;
      }
    }
    .ant-btn-text {
      span {
        text-decoration: underline;
      }

      i {
        display: none;
      }
    }
  }
}
.tooltip-field-item {
  margin: 0 4px;
}
