.sponsorship-review {
  padding: 35px 52px;
  width: 100%;
  color: #3b5266;
  background-color: #f1f1f6;
  font-size: 16px;

  .top-bar {
    .status-tag {
      margin: 0px auto 0px 10px;
    }

    .action {
      border: 1px solid #304659;
      padding: 0;
      cursor: pointer;
      height: 56px;
      width: 56px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fff;
    }
  }

  .title {
    font-size: 45px;
    font-weight: 900;
    color: #304659;
    margin-bottom: 0;
  }

  .des {
    margin-top: 18px;
    margin-bottom: 40px;
    color: #3b5266;
    max-width: 670px;
  }

  .sub-title {
    margin: 30px 0 20px;

    .sub-title-wrap {
      display: flex;
      align-items: center;

      .status-tag {
        margin: 0px auto 0px 10px;
      }
    }
  }

  .subsidized-wrap-container {
    .subsidized-wrap {
      .subsidized-title {
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        color: #000000;
        display: flex;
        align-items: center;

        .title-left {
          flex: auto;
          width: 0px;
        }
      }

      .subsidized-sub-title {
        font-weight: 700;
        font-size: 16px;
        line-height: 26px;
        color: #000000;
        margin: 8px 0px;
      }

      .subsidized-label {
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        color: #797F84;
        margin-top: 8px;
      }
    }

  }

  .review-card-container {
    display: none;

    &.show {
      display: block;
    }
  }

  .card-wrap {
    flex: 1;
    padding: 0 32px 32px;
    margin-bottom: 24px;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.08);
  }

  .ant-form.ant-form-vertical {
    display: none;
    background-color: #fff;
    padding: 0 32px 32px;
    border: 1px solid #e6e6e6;
    box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.08);

    &.show {
      display: block;
    }

    .textarea-wrap {
      textarea {
        min-height: 160px !important;
      }
    }

    .row {}
  }

  .radio-wrap {
    margin-top: 10px;

    .radio-selection {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  .btn-approve {
    margin-left: 30px;
  }

  .btn-draft {
    margin-top: 40px;
  }

  .upload-item-wrap {
    width: 100%;
    margin-bottom: 24px;

    .ant-upload-select {
      width: 100%;
    }

    .children-upload {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      // background: #f1f1f6;
      background: #fff;
      border: 1px dashed #e0e0e0;
      padding: 24px;
      cursor: pointer;

      .ant-upload-drag-icon {
        color: #00ff6d;
        font-size: 32px;
        margin-bottom: 16px;
      }

      .ant-upload-text {
        font-size: 16px;
        margin-bottom: 8px;
      }

      .ant-upload-hint {
        color: #696969;
      }
    }

    .ant-upload-list {
      .ant-upload-list-item {
        background: #fff;
      }

      button {
        border: none;
      }
    }
  }
}