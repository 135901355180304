.admin-application-list {
    padding: 50px 40px 30px;
    .topbar{
        margin-bottom: 0px;
        .title {
            padding-left: 12px;
            font-size: 45px;
            font-weight: 900;
            display: inline-block;
            margin-right: auto;
        }
        .ant-btn{
            margin: 4px 10px;
            &:hover,&:focus {
                background-color: #D7DEE4;
                border-radius: 4px;
                color: #2D2E3D;            
            }
        }
    }
    .filters {
        margin-bottom: 24px;
        .filter-item {
            flex: 1;
            margin-left: 16px;
            &:first-child {
                margin-left: 0;
            }
            &.ant-dropdown-link {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 56px;
                height: 56px;
                max-width: 56px;
                border: 1px solid #DDDFE4;
            }
        }
    }
    .statistics {
        border: 1px solid #DDDFE4;
        background-color: white;
        margin: 30px 0;
        padding: 24px 32px;
        padding-top: 32px;
        .num-item {
            width: 48%;
            color: #000;
            .num-title {
                font-size: 10px;
                color: #797F84;
            }
            .total-title {
                font-size: 14px;
                color: #797F84;
                font-weight: bolder;
            }
            .num {
                font-size: 32px;
                font-weight: bold;
            }
        }
        .total-divider{
            border-left: 6px solid rgba(0, 0, 0, 0.7);
        }
        .ant-divider-vertical {
            height: 4.5em;
        }
    }
}
