@import "../theme";

.event-preview-container {
  width: 100%;
  .preview-btn {
    margin-bottom: 24px;
    @media @mobile {
      margin: 0;
    }
  }
  .event-header {
    width: 100%;

    color: #fff;
    background: url("../../images/event_bg.png") center center no-repeat;
    background-size: cover;
    position: relative;
    padding-bottom: 80px;
    // @media @desktop {
    //   min-height: 580px;
    // }
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.6);
      z-index: 0;
    }
    @media @mobile {
      padding-bottom: 20px / @pv;
    }
    .home-wrap {
      position: relative;
      a {
        color: inherit;
        &.logo-item {
          color: #00ff6d;
        }
      }
    }
    .header-content {
      position: relative;
      padding: 80px 140px 0;
      h4 {
        text-transform: uppercase;
        color: #a0a0a0;
        font-size: 16px;
        letter-spacing: 1px;
      }
      h1 {
        font-size: 40px;
        color: #fff;
        margin-bottom: 12px !important;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      .event-content-user {
        margin-left: -4px;
        img {
          width: 24px;
          height: 24px;
          border-radius: 24px;
          margin-right: 6px;
        }
      }
      .icon-block-wrap {
        width: 440px;
        margin-bottom: 40px;
        p {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          & > .anticon,
          & > svg {
            margin-right: 16px;
            margin-top: 3px;
          }
        }
      }
      .filter-item {
        display: inline-flex;
        border: 1px solid #fff;
        height: 40px;
        width: 40px;
        padding: 8px 7px;
        color: #fff;
        margin-left: 16px;
      }
      .ant-btn {
        margin-right: 12px;
      }
    }
    .caption-container {
      position: absolute;
      right: 32px;
      bottom: 16px;
      p {
        color: #fff;
      }
    }
  }
  .event-content {
    width: 100%;
    padding: 80px 156px;
    color: #696969;
    p {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      // -webkit-line-clamp: 5;
      // overflow: hidden;
      // margin-bottom: 40px;
      margin-top: 4px;
      min-height: 25px;
    }
    .contact-info {
      .info-btn-receive {
        margin-bottom: 16px;
      }
    }
    label {
      cursor: pointer;
      &:before {
        content: "read more";
        text-transform: uppercase;
        font-weight: 900;
        color: #000;
        letter-spacing: 1px;
        text-decoration: underline;
      }
      .anticon {
        margin-left: 6px;
      }
    }
    input[type="checkbox"] {
      &:checked {
        & ~ p {
          -webkit-line-clamp: 100;
        }
        & ~ label {
          &:before {
            content: "read less";
          }
          .anticon {
            transform: rotate(180deg);
          }
        }
      }
    }
    img {
      max-width: 100%;
    }
  }
  .event-photo-list {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 24px 140px;
    .caption-container {
      max-width: calc(50% - 30px);
      flex-grow: 1;
      flex-shrink: 0;
      margin: 15px;
      img {
        width: 100%;
        height: 370px;
        // object-fit: cover;
        object-fit: contain;
      }
    }
  }

  .sub-infos {
    border: 1px solid #dddfe4;
    box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.08);
    padding: 32px;
    width: calc(100% - 256px);
    margin: 40px 128px;
    @media @mobile {
      width: calc(100% - 12px);
      margin: 10px 6px;
    }
    font-size: 16px;
    div > p {
      min-height: 25px;
    }
    .sub-title {
      font-size: 24px;
      color: #000;
    }
    .documents {
      margin-top: 10px;
      .doc {
        border: 1px solid #dddfe4;
        padding: 15px;
        margin-bottom: 10px;
        a {
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: #000;
        }
        img {
          display: inline-block;
          margin-left: 10px;
          cursor: pointer;
          margin: 0 20px;
          &:last-child {
            margin-left: auto;
          }
        }
      }
    }
  }
  @media @mobile {
    .event-header {
      height: auto;
      .home-wrap {
        background-color: transparent;
        box-shadow: none;
        padding: 24px / @pv 32px / @pv;
      }
      .header-content {
        padding: 24px / @pv 32px / @pv;
        .filter-item {
          position: absolute;
          right: 32px / @pv;
          top: 16px / @pv;
        }
        h4 {
          margin-bottom: 16px / @pv;
        }
        h1 {
          line-height: 50px / @pv;
        }
        img {
          width: 24px / @pv;
          height: 24px / @pv;
          border-radius: 24px / @pv;
        }
        .icon-block-wrap {
          width: 100%;
          span {
            &:last-child {
              margin-top: 0;
              white-space: normal;
              word-break: break-word;
            }
          }
        }
      }
    }
    .event-content {
      padding: 32px / @pv;
    }
    .event-photo-list {
      width: 100%;
      padding: 4px / @pv 0 0;
      .caption-container {
        max-width: 100%;
        width: 100%;
        margin: 0;
        img {
          width: 100%;
          height: auto;
          object-fit: cover;
        }
      }
    }
  }
}

.grant-preview-container {
  .event-header {
    background: #fff;
    box-shadow: inset 0px -1px 0px #dddfe4;
    height: auto;
    color: #000;
    padding-bottom: 50px;
    &::before {
      display: none;
    }
    .home-wrap a,
    .logo-item {
      color: inherit !important;
    }
    .home-wrap {
      border-bottom: 1px solid #e0e0e0;
    }
    .header-content {
      h1 {
        color: #000;
      }
      .sub-title {
        color: #000;
        margin-bottom: 16px;
        cursor: pointer;
        font-size: 40px;
        font-weight: bold;
        font-family: RobotoBold;
      }
      .filter-item {
        color: #000;
        border-color: #e0e0e0;
      }
      .icon-block-wrap {
        color: #797f84;
        p {
          // width: 70%;
          span {
            flex: 1;
            &:last-child {
              color: #4a4a4a;
              width: 50%;
            }
          }
        }
      }
      .ant-btn-text {
        span {
          text-transform: uppercase;
          text-decoration: underline;
        }
      }
    }
  }
  @media @mobile {
    .event-header {
      padding-bottom: 12px / @pv;
      .header-content {
        h4 {
          line-height: 26px / @pv;
          margin-bottom: 8px / @pv;
        }
        h1 {
          margin-top: 8px / @pv;
          font-size: 40px / @pv;
          line-height: 50px / @pv;
        }
        .icon-block-wrap {
          .flex {
            span {
              &:last-child {
                flex: 2;
              }
            }
          }
        }
        & > .flex {
          justify-content: flex-start;
          flex-direction: column;
          align-items: flex-start;
          .ant-btn-text {
            margin-top: 8px / @pv;
            padding-left: 0;
          }
        }
      }
    }
    .event-content {
      img {
        width: 100% !important;
      }
    }
  }
}

.project-preview-container {
  .event-header {
    width: 100%;
    height: auto;
    padding-bottom: 0px !important;
    background: transparent;
    color: #000;
    position: relative;
    .back-to-btn {
      position: absolute;
      right: 274px;
      top: 10px;
      z-index: 1;
    }
    a.logo-item {
      color: inherit !important;
    }
    .header-content {
      h1 {
        color: inherit !important;
      }
      p {
        color: #4a4a4a;
      }
    }
    &:before {
      display: none;
    }
  }
  .event-media {
    width: 100%;
    padding: 80px 156px 0;
    img {
      width: 100%;
    }
    .plyr {
      margin-bottom: 0px;
    }
    .feature-photo {
      width: 100%;
      height: auto;
      margin-bottom: 40px;
    }
  }
  @media @mobile {
    .event-header {
      padding-bottom: 0 / @pv;
    }
    .event-media {
      padding: 40px / @pv 0 24px / @pv;
    }
  }
}

.donation-preview-container {
  .content-user {
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    text-transform: lowercase;
    cursor: pointer;
    img {
      width: 28px;
      height: 28px;
      border-radius: 40px;
      margin-right: 8px;
    }
  }
  .filter-item {
    height: 38px;
    width: 38px;
    margin-left: 16px;
    border: 1px solid #e0e0e0;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .media-play-info {
    .feature-photo {
      width: 100%;
      height: auto;
      margin-bottom: 40px;
      img {
        width: 100%;
      }
    }
    position: relative;
    .text-info {
      h3 {
        font-size: 24px;
        margin-bottom: 24px;
      }
      span {
        color: #a0a0a0;
      }
      .photolist {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        img {
          max-width: calc(50% - 30px);
          height: 370px;
          object-fit: cover;
          flex-grow: 1;
          flex-shrink: 0;
          margin: 15px;
        }
      }
    }
  }
  .event-content {
    width: 100%;
    color: #696969;
    margin-bottom: 32px;
    p {
      display: block;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      // -webkit-line-clamp: 5;
      // overflow: hidden;
      // text-overflow: ellipsis;
      margin-bottom: 16px;
      /* autoprefixer: off */
      p {
        min-height: 25px;
      }
    }
    label {
      cursor: pointer;
      &:before {
        content: "read more";
        text-transform: uppercase;
        font-weight: 900;
        color: #000;
        letter-spacing: 1px;
        text-decoration: underline;
      }
      .anticon {
        margin-left: 6px;
      }
    }
    input[type="checkbox"] {
      &:checked {
        & ~ p {
          -webkit-line-clamp: 100;
        }
        & ~ label {
          &:before {
            content: "read less";
          }
          .anticon {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
  .donation-photo-list {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    padding: 0;
    .caption-container {
      width: 45%;
    }
    img {
      // width: auto;
      // height: 370px;
      width: 100%;
      object-fit: contain;
      // flex-grow: 1;
      // flex-shrink: 0;
      // margin: 15px;
    }
  }
  @media @mobile {
    padding: 40px / @pv 0px / @pv;
    .donate-info {
      width: 100%;
      padding: 0 32px / @pv 44px / @pv !important;
      border-bottom: 0 !important;
      overflow: inherit !important;
      .text {
        width: 100%;
      }
      h2 {
        width: 100%;
      }
      .filter-item {
        position: absolute;
        top: -8px / @pv;
        right: 32px / @pv;
      }
      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0px / @pv;
        left: 0px / @pv;
        height: 1px;
        background: #e0e0e0;
      }
    }
    .media-play-info {
      width: 100%;
      padding: 32px / @pv 0 0 !important;
      .text-info {
        padding: 0 32px / @pv;
        .photolist {
          width: 100%;
          img {
            margin: 0 0 32px / @pv;
            width: 100%;
            max-width: 100%;
          }
        }
      }
    }
    .event-content {
      line-height: 26px / @pv;
      margin-bottom: 32px / @pv;
      p {
        margin-bottom: 16px / @pv;
        p {
          min-height: 25px;
        }
      }
      label {
        .anticon {
          margin-left: 6px / @pv;
        }
      }
    }
    .donation-photo-list {
      width: 100%;
      padding: 0;
      .caption-container {
        width: 100%;
        padding: 10px / @pv;
      }
      img {
        max-width: 100%;
        width: 100%;
        margin: 0 0 40px / @pv;
      }
    }
  }
}
