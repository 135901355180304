@import "~antd/dist/antd.less";
@import "../styles/theme.less";
@import "../styles/App.less";

.grey-btn {
  background-color: #d7dee4;
  border-radius: 4px;
  color: #2d2e3d;
  height: 40px;
  border: none !important;
}
.light-grey-btn {
  height: 40px;
  border: none !important;
  border-radius: 4px;
  color: #2d2e3d;
  background-color: #f5f9fc;
}
.ant-tooltip {
  .ant-tooltip-arrow-content {
    background-color: white;
  }
  .ant-tooltip-inner {
    padding: 0;
    background-color: white;
  }
}

.admin-common-container {
  padding: 40px;
  min-height: 100%;
  background-color: #f1f1f6;
  h1 {
    font-size: 48px;
    line-height: 1;
    margin-bottom: 28px;
  }
  .search-container {
    margin-bottom: 24px;
    .left-wrap {
      flex: 0 0 calc(100% - 80px);
      .field-form-items {
        margin-right: 16px;
        height: 58px;
        position: relative;
        width: calc(40% - 25px);
        max-width: 270px;
        & > span {
          font-size: 10px;
        }
        .ant-select {
          position: relative;
          top: -2px;
          .ant-select-selector {
            border: none;
            box-shadow: none;
          }
        }
      }
    }
    .filter-item {
      height: 56px;
      width: 56px;
      border: 1px solid #e0e0e0;
      background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .clear-all {
      margin-left: 18px;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 900;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .table-container {
    background-color: #fff;
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.08);
    .ant-table {
      .ant-table-title,
      .ant-table-footer {
        font-family: RobotoBold;
        color: #000;
        font-weight: 900;
        border-bottom: 1px solid #f0f0f0;
        background-color: #fff;
        padding-right: 32px;
        .footer-item {
          margin-bottom: 0;
          span {
            i {
              width: 100px;
              padding: 0;
              text-align: right;
              &:last-child {
                width: 80px;
              }
            }
          }
        }
      }
      .ant-table-selection-column {
        padding-left: 16px;
      }
      .ant-table-thead > tr > th {
        background-color: #fff;
        text-transform: uppercase;
        color: #a0a0a0;
        font-weight: bolder;
        font-size: 12px;
        letter-spacing: 1px;
      }
      .ant-table-tbody > .ant-table-cell {
        color: #696969;
      }
      .ant-table-title {
        padding-right: 16px;
        .ant-input-search {
          width: 200px;
          height: 26px;
          border: none;
          input {
            border: 0;
            width: 160px;
            box-shadow: none;
            padding: 0 0 0 11px;
            height: 26px;
            line-height: 26px;
            font-weight: normal;
            font-family: Roboto;
            color: #000;
            text-align: right;
            &::placeholder {
              color: #a0a0a0;
              text-align: right;
            }
          }
          button {
            border: 0;
            height: 26px;
            line-height: 32px;
            box-shadow: none;
            padding: 0;
          }
        }
      }
      .ellipsis-custom-cell {
        max-width: 200px;
      }
    }
    .ant-pagination {
      padding-right: 32px;
    }
  }
}

.admin-filter-modal {
  h4 {
    font-weight: 700;
    font-size: 16px;
    font-family: RobotoBold;
  }
  .ant-select-selection-overflow {
    flex-wrap: nowrap;
    max-width: 400px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.ant-form-item-has-error {
  border-color: #ff4d4f;
  .ant-form-item-label label {
    color: #ff4d4f;
    background-color: #fff !important;
  }
  .ant-select-selector {
    border: 0 !important;
  }
}

.grant-preview-container {
  .preview-btn {
    background-color: #dddfe4;
    height: 64px;
    width: 100%;
    padding: 8px 136px;
    .ant-btn {
      margin-right: 16px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.ant-spin-dot .ant-spin-dot-item {
  background-color: #1da57a;
}
