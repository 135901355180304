@import "../../../styles/theme.less";
.admin-admin-container {
  .artist-cell-roles {
    .ant-tag {
      margin-right: 8px;
      text-transform: capitalize;
    }
  }

  .upload-item-wrap {
    .ant-upload-select-picture {
      width: 100%;
    }
  }
}
.admin-set-password-container {
  &.sign-container.sign-up-container.sign-in-container > .left-wrap {
    background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 112.39%),
      url("../../../images/admin_set_password.jpg") 80% ~"60%/110%" no-repeat;
  }
  &.sign-container.sign-up-container .left-wrap i {
    left: 135px;
    bottom: 40px;
    font-weight: normal;
  }
}
