.grant-post {
  padding: 35px 52px;
  width: 100%;
  color: #3b5266;
  background-color: #f1f1f6;
  .title {
    font-size: 45px;
    font-weight: 900;
    color: #304659;
  }
  .des {
    color: #3b5266;
    margin-bottom: 24px;
  }
  .sub-title {
    margin: 24px 0 20px;
  }
  .ant-form.ant-form-vertical {
    padding: 0 32px 32px;
    margin-bottom: 24px;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.08);
    .ant-checkbox-wrapper,
    .ant-radio-group,
    .upload-crop-container {
      margin-bottom: 18px;
    }
    .ant-upload {
      width: 100%;
      .upload-btn {
        margin-top: 6px;
        width: 100%;
      }
    }
  }
  .row {
    .btn-preview {
      margin-left: 30px;
    }
  }
}
