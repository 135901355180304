.admin-sign-container {
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  .admin-sign-in {
    width: 440px;
    height: 500px;
    margin: 200px auto;
  }
}
