.text-editor-comp {
  .DraftEditor-editorContainer {
    .public-DraftEditor-content {
      word-break: break-word;
      & > div {
        div[data-block="true"] {
          padding: 6px 0;
        }
        .bfa-left > div {
          display: block;
        }
      }
    }
  }
}
.bf-modal {
  .bf-modal-body {
    .bf-switch-external-type {
      visibility: hidden;
    }
  }
}
