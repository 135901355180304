@import "../../../styles/theme.less";
.admin-donors-container {
  .artist-cell-roles {
    .ant-tag {
      margin-right: 8px;
      text-transform: capitalize;
    }
  }
  .ant-table-tbody > tr > td.interest-col {
    max-width: 280px;
  }
}

.admin-user-modal {
  .ant-modal-body {
    padding-bottom: 0;
    .tag-list {
      .ant-tag {
        margin-right: 10px;
        margin-bottom: 10px;
      }
    }
    .add-user-tag {
      font-weight: bold;
      color: black;
      text-decoration: underline;
      position: relative;
      top: -16px;
      cursor: pointer;
    }
  }
}
