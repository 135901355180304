@import "../theme";

.user-profile-container {
  margin: 0 -140px;
  padding: 0;
  @media @mobile {
    margin: 0;
  }
  button {
    &.ant-btn {
      margin-left: 24px;
    }
  }
  section {
    width: 100%;
    padding: 80px 140px;
    position: relative;
    &:not(:last-child) {
      border-bottom: 1px solid #dadada;
    }
    &:nth-of-type(2n) {
      //background-color: #f5f9fc;
    }
    //edit-bar
    .edit-bar-wrap {
      position: absolute;
      top: 0;
      padding: 8px 32px;
      background: #e0e0e0;
      box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.08);

      button {
        height: 48px;
        line-height: 48px;
      }
      .model-wrap {
        display: inline-flex;
        margin-left: 40px;
        background-color: transparent !important;
        @media @mobile {
          margin-left: 6px;
        }
        & > .flex {
          padding: 8px;
          margin-right: 16px;
          background-color: #fff;
          @media @mobile {
            border: 1px solid #dddfe4;
            padding: 5px 8px;
            margin-right: 0;
            box-shadow: 4px 4px 14px rgb(0 0 0 / 8%);
          }
          span {
            margin-bottom: 0;
            height: 32px;
            line-height: 32px;
          }
          .ant-select {
            width: 90px;
          }
          &:last-child {
            .ant-select {
              width: 150px;
            }
          }
        }
      }
    }
    @media @mobile {
      margin: 0;
      padding: 24px / @pv 32px / @pv !important;
      overflow: hidden;
      .edit-bar-wrap {
        background-color: transparent;
        box-shadow: none;
        padding: 0;
        right: 20px / @pv;
        top: 16px / @pv;
        z-index: 1;
        i {
          display: none;
        }
        display: flex;
        justify-content: flex-end;
        button {
          padding: 0;
          height: 40px / @pv;
          width: 40px / @pv;
          line-height: 40px / @pv;
          border-color: #dddfe4;
          margin-left: 8px / @pv !important;
          box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.08);
        }
      }
    }
  }
  .user-info {
    padding-top: 92px;
    padding-bottom: 92px;
    .tools{
      display: inline-flex;
      .filter-item{
        height: 47px;
        width: 47px;
        border: 1px solid #a0a0a0;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .img-wrap {
      position: relative;
      flex: 0 0 240px;
      height: 240px;
      width: 240px;
      margin-left: 200px;
      background: #ccc;
      //border: 1px solid #000;
      &:after {
        content: "";
        display: block;
        height: 240px;
        width: 240px;
        border: 1px solid #000;
        position: absolute;
        top: -20px;
        right: -20px;
      }
      .caption-container {
        p {
          width: 100%;
          text-align: right;
        }
      }
    }
    img {
      height: 100%;
      width: 100%;
      box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.16);
    }
    h1 {
      font-size: 48px;
      line-height: 58px;
      margin-bottom: 16px;
      font-weight: 700;
    }
    h4 {
      font-weight: normal;
      margin-bottom: 32px;
      color: #696969;
      text-transform: capitalize;
    }
    p {
      margin-bottom: 0;
      button {
        min-width: 104px;
        margin-left: 0;
        margin-right: 24px;
        &:hover {
          background-color: #000;
          color: #00ff6d;
          border-color: #000;
        }
      }
      @media @desktop {
        .ant-dropdown-link {
          display: none;
        }
      }
    }
    .ant-anchor-wrapper {
      margin: 0;
      padding: 0;
      background-color: transparent;

      .ant-anchor-ink {
        display: none;
      }
      .ant-anchor-link {
        padding: 0;
      }
    }
    .event-btn {
      &:disabled {
        .ant-anchor-link-title {
          color: #dddfe4 !important;
        }
      }
      &:hover {
        .ant-anchor-link-title {
          color: #00ff6d;
        }
      }
    }

    @media @mobile {
      flex-flow: column-reverse nowrap;
      align-items: flex-start !important;
      .img-wrap {
        margin-left: 0;
        margin-top: 20px;
        margin-bottom: 24px;
        width: 160px / @pv;
        height: 160px / @pv;
        flex: 0 0 160px / @pv;
        &:after {
          height: inherit;
          width: inherit;
        }
      }
      p {
        display: flex;
        & > span {
          display: none;
        }
      }
      .ant-dropdown-link {
        display: inline-block;
        height: 48px;
        width: 48px;
        padding-top: 10px;
        margin-left: 16px;
        border: 1px solid #e0e0e0;
        text-align: center;
      }
    }
  }
  .donate-info {
    //padding: 38px 140px 120px;
    padding-top: 80px;
    .edit-bar-wrap {
      button {
        margin-left: 24px;
      }
    }
    p {
      margin-bottom: 16px;
      text-transform: uppercase;
      color: #aaa;
      letter-spacing: 1px;
    }
    .crowd-item-container {
      p {
        margin-bottom: 8px;
        &.raised {
          span {
            font-size: 32px;
            line-height: 42px;
            color: #000;
            i {
              margin-left: 6px;
              font-size: 16px;
              color: #4a4a4a;
              text-transform: none;
            }
          }
        }
      }
      .ant-progress {
        margin-bottom: 40px;
        .ant-progress-inner {
          border-radius: 0;
        }
      }
      .donate-time {
        font-weight: 700;
        margin-left: 24px;
        text-transform: none;
        color: #000;
        i {
          margin-right: 6px;
          font-weight: 400;
          color: #717171;
        }
      }
      .link {
        color: #000;
        text-decoration: underline;
        font-size: 14px;
        cursor: pointer;
        font-weight: 900;
      }
      @media @desktop {
        .dm {
          display: inline;
        }
        .mb {
          display: none;
        }
      }
      @media @mobile {
        p {
          justify-content: flex-start;
          & > span {
            font-family: RobotoBold;
            font-weight: 900;
            &:last-child {
              display: none;
            }
          }
          i {
            font-family: Roboto;
            font-weight: normal;
          }
          .dm {
            display: none;
          }
          .mb {
            display: inline;
          }
          &:last-child {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            & > span:last-child {
              display: inline;
            }
            span.donate-time {
              margin-left: 8px / @pv;
              display: inline;
              font-size: 12px;
            }
          }
        }
        .ant-progress {
          margin-bottom: 24px / @pv;
        }
        .donate-item-wrap {
          flex-direction: column !important;
          justify-content: flex-start !important;
          align-items: flex-start !important;
          span.link {
            font-size: 14px / @pv;
            margin-top: 16px / @pv;
            display: inline;
          }
        }
      }
    }
    h2 {
      font-size: 40px;
      line-height: 50px;
      margin-bottom: 16px;
      font-weight: 900;
      @media @mobile {
        margin-bottom: 8px / @pv;
      }
    }
    h4 {
      max-width: 570px;
      margin-bottom: 40px;
      line-height: 26px;
      font-weight: normal;
      color: #696969;
      @media @mobile {
        margin-bottom: 24px / @pv;
      }
    }

    button {
      margin-left: 0px;
    }

    .ant-divider {
      margin-top: 120px;
      margin-bottom: 0;
    }
  }
  .about-info {
    //padding: 100px 140px;
    padding-top: 100px;

    & > h1 {
      font-size: 72px;
      line-height: 82px;
      margin-bottom: 30px;
      font-weight: 900;
      @media @mobile {
        font-size: 32px / @pv;
        margin-bottom: 16px / @pv;
        line-height: 42px / @pv;
      }
    }
    .about-wrap {
      width: 100%;
      @media @mobile {
        flex-flow: column nowrap;
      }
    }
    img {
      width: 570px;
      margin-right: 130px;
      @media @desktop {
        flex: 0 0 570px;
      }

      @media @mobile {
        width: 100% !important;
        margin: 0 0 16px / @pv 0;
      }
    }
    span {
      font-size: 16px;
      margin-bottom: 12px;
      color: #696969;
      &.about-text {
        width: 100%;
      }
    }
    .rich-text {
      width: 100%;
      p {
        min-height: 25px;
      }
      img {
        margin: unset;
        flex: unset;
      }
    }
    //div:not(.edit-bar-wrap) {
    //  padding: 0;
    //  margin-bottom: 0;
    //  background-color: #fff;
    //
    //
    //}
    .edit-bar-wrap {
      button {
        span {
          color: #00ff6d !important;
        }
      }
    }
    @media @mobile {
      .edit-bar-wrap {
        & + h1 {
          margin-top: 50px;
        }
      }
    }
  }
  .media-info {
    min-height: 1050px;
    .ant-carousel {
      @media @desktop {
        width: 970px;
        margin: 30px auto 0;
      }
    }
    @media @mobile {
      width: 375px / @pv;
      min-height: 880px / @pv;
      padding: 24px / @pv 0 120px / @pv !important;
    }

    .slick-list {
      @media @mobile {
        padding: 0 !important;
      }
      .slick-track {
        @media @mobile {
          height: auto;
          display: flex;
          .slick-slide {
            width: 100vw;
          }
        }
      }
    }

    .media-list {
      margin-top: 150px;
      @media @mobile {
        margin-top: 98px / @pv;
      }
      .media-list-cell {
        width: 770px;
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: flex-start;
        @media @mobile {
          width: 375px;
        }
        .media-list-content {
          @media @mobile {
            margin: 0;
          }
          .poster-wrap {
            position: relative;
            width: 740px;
            height: 417px;
            z-index: 1;
            padding: 0;
            margin: 0 auto;
            position: relative;
            &:before {
              content: "";
              display: block;
              position: absolute;
              top: 0;
              right: 0;
              left: 0;
              bottom: 0;
              background-color: rgba(0, 0, 0, 0.35);
              z-index: 1;
            }
            @media @mobile {
              width: 100%;
              height: 400px / @pv;
            }
            &.hidden {
              visibility: hidden;
            }
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
            svg {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              z-index: 2;
              cursor: pointer;
            }
          }
          span {
            width: 100%;
            word-break: break-all;
            display: block;
          }
          .media-description {
            display: none;
            @media @desktop {
              width: 770px;
            }

            margin: 40px auto 0;

            &.show {
              display: block;
              @media @mobile {
                margin-top: 24px / @pv;
                h3 {
                  font-size: 24px / @pv;
                }
                span,
                h3 {
                  padding: 0 32px / @pv;
                }
              }
            }
          }
        }

        span {
          color: #696969;
          margin-bottom: 16px;
          padding: 0 18px;
        }
        h3 {
          font-weight: 700;
          font-size: 32px;
          line-height: 42px;
          margin-bottom: 16px;
          padding: 0 18px;
        }
      }
      .slick-dots li {
        text-indent: 0;
        width: 40px;
        height: 40px;
      }
    }
    .line-wrap {
      width: 970px;
      height: 810px;
      position: absolute;
      top: 119px;
      left: 50%;
      transform: translateX(-50%);
      border: 1px solid #000;
      h1 {
        position: absolute;
        font-size: 48px;
        line-height: 58px;
        font-weight: 700;
        background-color: #fff;
        padding: 0 8px;
        top: -30px;
        left: 90px;
        z-index: 2;
        @media @mobile {
          top: 48px / @pv;
          left: 32px / @pv;
          padding: 0;
          margin-bottom: 24px / @pv;
          line-height: 42px / @pv;
          font-size: 32px / @pv;
        }
      }
      .play_btn {
        position: absolute;
        left: 50%;
        transform: translate(-53%, 256px);
        cursor: pointer;
        z-index: 10;
        @media @mobile {
          transform: translate(-53%, 286px / @pv);
        }
      }
      .media-button {
        position: absolute;
        bottom: -24px;
        left: 12%;
        button {
          padding: 0;
          border: 0 !important;
          width: auto;
          margin-left: 0;
          outline: none;
          box-shadow: none !important;
          &:first-child {
            margin-right: 24px;
          }
          .anticon {
            margin-right: 0;
            outline: none !important;
          }
        }

        .anticon {
          width: 48px;
          height: 48px;
          line-height: 48px;
          border: 1px solid #aaa;
          background-color: #fff;
          cursor: pointer;
        }
      }
      .play-container {
        opacity: 0;
        &.active {
          opacity: 1;
        }
      }
      .plyr {
        position: absolute;
        top: 110px;
        left: 114px;
        width: 740px;
        height: 416px;
        min-height: 416px;
        outline: none;
        border: none;
        box-shadow: none;
        z-index: 0;
        &.active {
          display: block;
        }
        @media @mobile {
          width: 375px / @pv;
          height: 416px / @pv;
          top: 120px / @pv;
          left: 0;
        }
      }
      @media @mobile {
        border: 0;
        width: 100%;
        height: auto;
        left: 0;
        bottom: 0;
        top: 0;
        transform: translateX(0);
        padding-top: 0 / @pv;
        .media-button {
          left: 32px / @pv;
          bottom: 32px / @pv;
        }
      }
    }
    .no-media {
      position: absolute;
      z-index: 9;
      left: 50%;
      transform: translateX(-50%);
      @media @desktop {
        min-width: 744px;
        top: 224px;
      }
      .media-list-content {
        .poster-wrap {
          width: 100%;
          height: 470px;
          background-color: #f9f9f9;
          display: flex;
          flex-flow: row nowrap;
          justify-content: center;
          align-items: center;
          box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.16);
        }
        .media-description {
          padding-top: 40px;
          span {
            color: #696969;
          }
          h3 {
            margin-top: 16px;
            font-size: 32px;
            line-height: 1;
            color: #000;
            margin-bottom: 16px;
          }
        }
      }
      @media @mobile {
        width: 86%;
        top: 120px / @pv;
      }
    }
  }
  .project-info {
    & > h1 {
      font-size: 48px;
      line-height: 58px;
      margin-bottom: 32px;
      font-weight: 700;
      @media @mobile {
        font-size: 32px / @pv;
        line-height: 40px / @pv;
        margin-bottom: 40px / @pv;
      }
    }
    .masonry {
      width: 100%;
      columns: 2; // 默认列数
      column-gap: 40px; // 列间距
      min-height: 514px;
      @media @mobile {
        columns: 1;
        min-height: auto;
      }
      .children-item {
        padding-top: 40px;
        max-height: 600px;
        page-break-inside: avoid;
        @media @mobile {
          padding-top: 0px;
          max-height: none;
        }
        img {
          cursor: pointer;
        }
      }
      .no-item {
        margin-top: 40px;
      }
      .item {
        border: 1px solid #f2f2f2;
        padding: 32px;
        width: 100%;
        break-inside: avoid;
        display: flex;
        flex-flow: column nowrap;
        position: relative;
        @media @mobile {
          padding: 16px / @pv;
        }

        img {
          margin-bottom: 32px;
          width: 100%;
        }
        h2 {
          font-size: 32px;
          line-height: 42px;
          margin-bottom: 16px;
          font-weight: 700;
          @media @mobile {
            font-size: 24px / @pv;
            line-height: 38px / @pv;
          }
        }
        span {
          line-height: 26px;
          margin-bottom: 16px;
          height: 26px;
          overflow: hidden;
          text-overflow:ellipsis;
          white-space: nowrap;
          color: #696969;
        }
        a {
          text-transform: uppercase;
          font-weight: 700;
          font-size: 14px;
          color: #000;
          text-decoration: underline;
        }
        .edit-btn {
          position: absolute;
          z-index: 9;
          right: -24px;
          top: -24px;
          background-color: #fff;

          display: block;
          cursor: pointer;
          align-items: center;
          justify-content: center;
          height: 48px;
          width: 48px;
          border: 1px solid #e0e0e0;
          .mb-btn-item {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
          }
        }
      }
    }
    .ant-divider {
      margin: 0;
    }
  }
  .event-info {
    .ant-divider {
      margin: 120px 0 0;
    }
    & > h1 {
      font-size: 48px;
      line-height: 58px;
      margin-bottom: 80px;
      font-weight: 700;
      @media @mobile {
        font-size: 32px / @pv;
        line-height: 42px / @pv;
        margin-bottom: 40px / @pv;
      }
    }
    .event-list {
      .event-cell {
        position: relative;
        margin-bottom: 56px;
        img {
          width: 266px;
          flex: 0 0 266px;
          height: 266px;
          object-fit: cover;
        }
        & > .flex {
          width: calc(100% - 266px);
          flex: 0 0 calc(100% - 266px);
          border: 1px solid #e2e2e2;
          border-left-width: 0;
          height: 266px;
          @media @mobile {
            height: auto;
          }
        }
        @media @mobile {
          width: 100%;
          flex-flow: column nowrap;
        }
        .event-content {
          padding: 30px 32px;
          height: 100%;
          width: calc(100% - 200px);
          flex: 0 0 calc(100% - 200px);
          max-width: 608px;
          h2 {
            font-size: 32px;
            line-height: 42px;
            margin-bottom: 16px;
            font-weight: 700;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 100%;
          }
          &.icon-block-wrap {
            margin-bottom: 0;
            @media @mobile {
              margin-bottom: 0;
            }
            p {
              display: flex;
              justify-content: flex-start;
              align-items: flex-start;
              color: #696969;
              @media @mobile {
                width: 100%;
                display: block;
                margin-bottom: 16px / @pv;
              }

              & > .anticon,
              & > svg {
                margin-right: 16px;
                margin-top: 3px;
                @media @mobile {
                  margin-right: 8px / @pv;
                  margin-top: 4px / @pv;
                }
              }
            }
          }
          @media @mobile {
            width: 100%;
            height: calc(100% - 53vw);
            flex: 0 0 calc(100% - 53vw);
          }
        }
        .event-button {
          padding: 0 32px 0 0;
          flex: 0 0 32px;
          @media @mobile {
            flex: 0 0 200px / @pv;
          }
          button {
            width: 168px;
            margin-left: 0;
            margin-bottom: 16px;
          }
        }
        @media @mobile {
          img {
            width: 100%;
            height: 266px / @pv;
          }
          & > .flex {
            width: 100%;
            flex-flow: column nowrap;
            border-left-width: 1px;
            border-top-width: 0px;
          }
          .event-content {
            padding: 16px / @pv;
            width: 100%;
            max-width: initial;
            h2 {
              font-size: 24px / @pv;
              line-height: 38px / @pv;
              width: 100%;
              white-space: normal;
            }
          }
          .event-button {
            width: 100%;
            border-top: 1px dashed #e0e0e0;
            padding: 32px / @pv 16px;
            button {
              width: 100%;
              margin-left: 0;
              margin-bottom: 16px / @pv;
            }
          }
        }
        .edit-btn {
          position: absolute;
          z-index: 9;
          right: -24px;
          top: -24px;
          background-color: #fff;

          display: none;
          cursor: pointer;
          align-items: center;
          justify-content: center;
          height: 48px;
          width: 48px;
          border: 1px solid #dddfe4;
          svg {
            width: 22px;
          }
        }
        &:hover .edit-btn {
          display: flex;
        }
      }
    }
  }
  .instagram-info {
    @media @mobile {
      padding-bottom: 0 !important;
    }
    h1 {
      font-size: 48px;
      line-height: 58px;
      margin-bottom: 80px;
      font-weight: 700;
      @media @mobile {
        font-size: 40px / @pv;
        line-height: 50px / @pv;
        margin-bottom: 32px / @pv;
      }
      span {
        text-transform: uppercase;
        font-size: 16px;
        color: #a0a0a0;
        font-family: RobotoBold;
        letter-spacing: 2px;
      }
      i {
        @media @mobile {
          display: block;
          width: 310px / @pv;
          font-size: 22px / @pv;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .instagram-list {
      span {
        img {
          width: 270px;
          height: 270px;
          object-fit: cover;
        }
        &:not(:last-child) {
          margin-right: 24px;
        }
      }
      @media @mobile {
        flex-wrap: wrap;
        margin: 0 -32px;
        span {
          flex: 0 0 187.5px / @pv;
          width: 187.5px / @pv;
          height: 187.5px / @pv;
          margin-right: 0 !important;
          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
  .follow {
    padding: 62px 140px 48px;
    background-color: #000 !important;
    color: #fff;
    @media @desktop {
      .flex.social-icon {
        justify-content: flex-end;
        a:not(:first-child) {
          margin-left: 40px;
        }
      }
    }
    @media @mobile {
      & > .flex {
        flex-flow: column nowrap;
        align-items: flex-start;
        h1 {
          margin-bottom: 16px / @pv;
        }
        span.flex {
          margin-top: 16px;
          flex: 1 !important;
          width: 100%;
          justify-content: flex-start;
          a:not(:first-child) {
            margin-left: 40px;
          }
          img {
            padding: 20px / @pv;
            border: 1px solid #999;
            flex: 0 0 21px;
          }
        }
      }
    }

    h1 {
      color: #fff;
      font-weight: 700;
      margin-bottom: 0;
    }
    img {
      filter: invert(1);
    }
    .ant-divider {
      margin-bottom: 0;
      color: #fff;
    }
  }
  .no-item {
    padding: 32px;
    border: 1px solid #e0e0e0;
    &.item {
      .bg-item {
        span {
          margin-bottom: 0;
          color: #00ff6d;
        }
      }
    }
    &.event-cell {
      .bg-item {
        width: 100%;
        height: 202px;
      }
    }
    .bg-item {
      height: 270px;
      background-color: #f9f9f9;
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
    }
  }
}

.ant-anchor-wrapper {
  @media @mobile {
    margin: 0;
    padding: 0;
    background-color: transparent;

    .ant-anchor-ink {
      display: none;
    }
    .ant-anchor-link {
      padding: 0;
    }
  }
}

.popover-btn-wrap {
  .ant-popover-inner-content {
    min-width: 400px;
    width: auto;
    p {
      button {
        margin-right: 16px;
      }
      & > span {
        font-size: 14px;
        color: #a0a0a0;
        margin-bottom: 16px;
      }
      &.flex {
        line-height: 40px;
        background-color: #efefefef;
        padding: 0 16px;
        font-size: 14px;
        color: #696969;
        i:last-child {
          color: #000;
          font-weight: bold;
          cursor: pointer;
          margin-left: 16px;
        }
      }
    }
    @media @mobile {
      min-width: auto !important;
      max-width: 80vw;
      p {
        i {
          &:first-child {
            flex: 0 0 calc(100% - 60px);
            width: calc(100% - 60px);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
  }
}

.edit-form-modal {
  .hidden {
    display: none;
  }
  .ant-btn-text {
    border: none;
    text-align: left;
    padding-left: 0;
    background-color: transparent;
    span:last-child {
      text-decoration: underline;
    }
  }
  .img-list-wrap {
    position: absolute;
    top: 50px;
    img {
      width: 140px;
      height: 140px;
    }
  }

  .info-wrap {
    position: relative;
    h2 {
      font-size: 24px;
      line-height: 38px;
    }
    .upload-wrap {
      margin-bottom: 28px;
      img {
        flex: 0 0 140px;
        height: 140px;
        margin-right: 22px;
      }
      .ant-btn.ant-btn-text {
        border: none;
        padding-left: 0;
        margin-top: 4px;
        span {
          text-decoration: underline;
        }
      }
    }
    .ant-checkbox-group {
      display: flex;
      flex-direction: column;
      label {
        margin-left: 0;
        line-height: 32px;
        font-size: 16px;
      }
    }
    .other-input {
      margin-top: 8px;
      display: none;
      &.show {
        display: block;
      }
    }
  }
  .donate-wrap {
    .donate-title {
      & > span {
        font-size: 16px;
        color: #696969;
        font-weight: normal;
        font-family: Roboto;
      }
    }
  }
  .about-wrap {
    .textarea-wrap textarea.ant-input {
      min-height: 160px !important;
    }
  }
  .project-wrap {
    h4 {
      font-weight: 900;
    }
    .ant-upload {
      width: 100%;
    }
  }
  .media-wrap {
    & > div {
      padding-bottom: 24px;
      .ant-form-item {
        margin-bottom: 5px;
      }
    }
    img {
      width: 100%;
    }
  }
  .event-wrap {
  }
  .instagram-wrap {
    .instagram-item {
      padding: 12px 24px;
      border: 1px solid #e0e0e0;
      img {
        height: 56px;
        width: 56px;
        margin-right: 8px;
      }
      p {
        margin-bottom: 0;
        i {
          font-size: 12px;
          color: #a0a0a0;
        }
      }
    }
  }
}

.full-profile-page {
  width: 100%;
  height: auto;
  min-height: auto;
  position: relative;
  main {
    min-height: 512px;
  }
}

.preview-header-container {
  width: 100%;
  height: 64px;
  padding: 0 140px;
  flex: 0 0 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: #dddfe4;
  position: sticky;
  top: 0;
  z-index: 100;
  button {
    height: 48px;
    line-height: 48px;
  }
  @media @mobile {
    justify-content: flex-end;
    padding: 0 32px / @pv;
    i {
      display: none;
    }
    button {
      height: 48px / @pv;
      line-height: 48px / @pv;
    }
  }
}
