.sponsorship-review {
  padding: 35px 52px;
  width: 100%;
  color: #3b5266;
  background-color: #f1f1f6;
  font-size: 16px;
  .top-bar {
    .status-tag {
      margin: 0px auto 0px 10px;
    }
    .action {
      border: 1px solid #304659;
      padding: 0;
      cursor: pointer;
      height: 56px;
      width: 56px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fff;
    }
  }
  .title {
    font-size: 45px;
    font-weight: 900;
    color: #304659;
    margin-bottom: 0;
  }
  .des {
    margin-top: 18px;
    margin-bottom: 40px;
    color: #3b5266;
    max-width: 670px;
  }
  .sub-title {
    margin: 30px 0 20px;
  }
  .review-card-container {
    display: none;
    &.show {
      display: block;
    }
  }
  .card-wrap {
    flex: 1;
    padding: 0 32px 32px;
    margin-bottom: 24px;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.08);
    h3 {
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 0;
    }
    p.flex {
      align-items: flex-start;
      span {
        display: inline-block;
        max-width: 70%;
        text-align: right;
        white-space: normal;
        overflow-wrap: break-word;
      }
    }
  }
  .field-form-items {
    width: 100%;
    margin-bottom: 40px;
  }
  .flex {
    & > .card-wrap:not(:last-child) {
      margin-right: 40px;
    }
  }
  .ant-form.ant-form-vertical {
    display: none;
    background-color: #fff;
    padding: 0 32px 32px;
    border: 1px solid #e6e6e6;
    box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.08);
    &.show {
      display: block;
    }
    .textarea-wrap {
      textarea {
        min-height: 160px !important;
      }
    }
    .row {
    }
  }
  .btn-approve {
    margin-left: 30px;
  }
  .btn-draft {
    margin-top: 40px;
  }
}
.admin-check-modal {
  .mb-24 {
    margin-bottom: 24px;
  }
  .customizeFee{
    font-size: 16px;
    font-weight: 400;
  }
  .customizeFee-radio{
    margin-top: 24px;
    margin-bottom: 24px;
  }
}
