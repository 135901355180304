@import "theme";

.app-container {
  header.sign-wrap {
    height: 72px;
    flex: 0 0 72px;
    box-shadow: inset 0px -1px 0px #dcdcdc;
    @media @mobile {
      padding: 24px 30px;
    }
  }
  .sign-main-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
  }
  main.sign-main {
    background-color: #f9f9f9;
    @media @mobile {
      padding: 24px 30px;
    }
  }
}

.sign-container {
  margin: 0 auto;
  background-color: #fff;
  h1 {
    font-size: 46px;
    text-align: left;
    font-weight: 900;
  }
  h4 {
    margin-bottom: 40px;
    text-align: left;
    color: #696969;
  }
  .other-sign {
    .ant-divider {
      color: @gray-color;
      margin-top: 40px;
      margin-bottom: 40px;
      &:before {
        width: 0;
      }
      span {
        padding-left: 0;
      }
    }
    .btns {
      display: flex;
    }
    button,
    .google-btn {
      min-width: 224px;
      height: 40px;
      font-weight: 900;
      color: #fff;
      border: 0;
      text-transform: initial;
      font-size: 16px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      .anticon {
        font-size: 26px;
        margin-top: 2px;
      }
      &:first-child {
        margin-right: 16px;
      }
      &.facebook-btn {
        background-color: #3b5998;
      }
      &.google-btn {
        background-color: #1a73e8;
      }
    }
  }
  .sign-form {
    form {
      width: 100%;
      h4 {
        color: #6b6b6b;
        text-align: left;
        margin-bottom: 20px;
      }
    }
    a {
      margin: 0 4px;
      text-transform: uppercase;
      font-weight: 900;
      color: #000;
      letter-spacing: 1px;
      text-decoration: underline;
    }
  }
  &.sign-up-container {
    flex: 1;
    margin: 0;
    width: 100%;
    .left-wrap,
    .sign-up-wrap {
      flex: 1;
      height: 100vh;
    }
    .left-wrap {
      // background: linear-gradient(180deg, #000000 0%, #000000 25%,  rgba(0, 0, 0, 0) 36%, rgba(0,0,0,0)),
      //   url("../images/sign-bg-new.jpg") 80% ~"60%/110%" no-repeat, linear-gradient(0deg, #000000 0%, #000000 25%, rgba(0, 0, 0, 0) 36%, rgba(0,0,0,0));
      //background-color: #0000;
      background: linear-gradient(
          to bottom,
          black 0%,
          black 24%,
          transparent 36%,
          transparent 72%,
          black 85%
        ),
        url("../images/signup_brass.jpg") 80% ~"60%/110%" no-repeat;
      &.after {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: block;
      }
      position: relative;
      overflow: hidden;
      span {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: -30px;
        display: block;
        height: 78%;
        width: 644px;
        border: 2px solid #00ff6d;
        border-top: 0;
        &:before {
          content: "";
          display: block;
          width: 19%;
          height: 2px;
          background-color: #00ff6d;
          position: absolute;
          top: 0;
          left: 0;
        }
        &:after {
          content: "";
          display: block;
          width: 35%;
          height: 2px;
          background-color: #00ff6d;
          position: absolute;
          top: 0;
          right: 0;
        }
        svg {
          position: absolute;
          top: -3%;
          left: 21%;
        }
      }
      i {
        position: absolute;
        left: 12%;
        bottom: 6px;
        font-size: 16px;
        font-weight: 900;
        line-height: 26px;
        color: #ffffff;
        &.caption {
          bottom: 30px;
        }
      }
    }
    &.sign-in-container > .left-wrap {
      background: linear-gradient(
          to bottom,
          black 0%,
          black 24%,
          transparent 36%,
          transparent 72%,
          black 85%
        ),
        url("../images/signin_weekend.jpg") 80% ~"60%/110%" no-repeat;
    }
    .sign-up-wrap {
      padding-top: 100px;
      h1,
      h4,
      .other-sign,
      .sign-form {
        width: 469px;
      }
    }
    @media @mobile {
      flex-direction: column;
      .left-wrap {
        flex: 0 0 300px / @pv;
        height: 300px / @pv;
        width: 100%;
        //background: linear-gradient(
        //    180deg,
        //    #000000 0%,
        //    rgba(0, 0, 0, 0) 112.39%
        //  ),
        //  url("../images/sign-bg.jpg") 56% ~"60%/146%" no-repeat;
        background: linear-gradient(
            to bottom,
            black 0%,
            black 8%,
            transparent 36%,
            transparent 70%,
            black 97%
          ),
          url("../images/signup_brass.jpg") 80% ~"60%/110%" no-repeat;
        span {
          display: inline;
          transform: none;
          height: auto;
          width: auto;
          border: 0;
          top: 24px / @pv;
          left: 30px / @pv;
          &:before,
          &:after {
            display: none;
          }
          svg {
            position: relative;
            top: auto;
            left: auto;
            height: 24px / @pv;
            width: auto;
          }
        }
        i {
          left: 8%;
          bottom: 6px / @pv;
          &.caption {
            bottom: 30px / @pv;
          }
        }
      }
      .sign-up-wrap {
        padding: 24px / @pv 30px / @pv;
        h1,
        h4,
        .other-sign,
        .sign-form {
          width: 100%;
          max-width: 100%;
        }
        h1 {
          font-size: 32px / @pv;
          font-weight: 900;
          margin-bottom: 8px / @pv;
        }
        h4 {
          font-size: 16px / @pv;
          line-height: 26px / @pv;
          font-weight: normal;
          margin-bottom: 32px / @pv;
        }
        .other-sign {
          button,
          .google-btn {
            width: 330px;
            min-width: auto;
            margin-right: 0;
            height: 48px / @pv;
            font-size: 16px / @pv;
            &:first-child {
              margin-bottom: 16px / @pv;
            }
          }
          .btns {
            display: block;
          }
        }
      }
    }
  }
  &.sign-up-container.sign-in-container {
    h1 {
    }
    a {
      text-transform: inherit;
    }
    @media @mobile {
      .left-wrap {
        background: linear-gradient(
            to bottom,
            black 0%,
            black 6%,
            transparent 36%,
            transparent 74%,
            black 97%
          ),
          url("../images/signin_weekend.jpg") 80% ~"60%/110%" no-repeat;
      }
    }
    .password-item {
      position: relative;
      a {
        position: absolute;
        right: 16px;
        bottom: 6px;
        text-transform: uppercase;
      }
    }
  }
}

.sign-flow-container {
  .step-wrap {
    @media @desktop {
      margin-top: 40px;
      margin-bottom: 16px;
    }
    color: #aaa;
    text-transform: uppercase;
    letter-spacing: 2px;
    i {
      margin: 0 4px;
    }
    @media @mobile {
      width: 100%;
      text-align: left;
      margin-bottom: 8px;
    }
  }
  h1 {
    // white-space: nowrap;
    padding: 0 30px;
    text-align: center;
    @media @mobile {
      width: 100%;
      text-align: left;
      font-size: 32px / @pv;
      line-height: 42px / @pv;
      white-space: normal;
    }
  }
  h4 {
    text-align: center;
    svg {
      vertical-align: top;
    }
    .sign-wrap-popover {
      @media @mobile {
        display: none;
      }
    }
    .mobile-popover {
      @media @desktop {
        display: none;
      }
    }
    @media @desktop {
      max-width: 560px;
    }
    @media @mobile {
      text-align: left;
      font-size: 16px / @pv;
      line-height: 26px / @pv;
    }
  }
  form {
    @media @desktop {
      margin: 0 auto;
      width: 370px !important;
    }
  }
  .base-container,
  .address-info-container {
    width: 770px;
    @media @mobile {
      width: 100%;
    }
  }
  .back-component-container {
    height: 48px;
    line-height: 48px;
    border: 1px solid #a0a0a0;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 14px;
    letter-spacing: 2px;
    padding: 0 24px;
    margin-bottom: 0;
    @media @mobile {
      display: none;
    }
  }
  button {
    @media @mobile {
      width: 100%;
    }
  }
  .hobby-container {
    @media @desktop {
      width: 770px;
      padding-bottom: 80px;
    }

    .hobby-btn {
      @media @desktop {
        padding-top: 24px;
        border-top: 1px solid #e0e0e0;
      }
    }
    .other-input {
      margin-bottom: 40px;
      @media @mobile {
        margin-bottom: 0;
      }
    }
  }
  .hobby-list {
    @media @desktop {
      width: 770px;
    }

    span {
      flex: 0 1 170px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 170px;
      margin-bottom: 30px;
      text-align: center;
      background-color: #fff;
      border: 1px solid #eaeaec;
      cursor: pointer;
      text-transform: uppercase;
      letter-spacing: 2px;
      @media @mobile {
        flex: 0 1 150px / @pv;
        height: 150px / @pv;
        margin-bottom: 16px / @pv;
        font-size: 14px / @pv;
      }
      &:hover {
        box-shadow: 6px 4px 14px rgba(0, 0, 0, 0.12);
      }
      &.active {
        background-color: #dfffed;
        border: 2px solid #00ff6d;
        font-weight: 900;
        padding: 0 12px;
        font-size: 14px;
      }
      &.disabled {
        background-color: #dfdfdf;
        color: gray;
        cursor: not-allowed;
      }
    }
  }
}

.sign-success-container {
  img {
    @media @desktop {
      margin-top: 164px;
      height: 130px;
      margin-bottom: 40px;
    }
    @media @mobile {
      margin-top: 24px / @pv;
      height: 130px / @pv;
      margin-bottom: 40px / @pv;
    }
  }
  h1 {
    text-align: center;
    font-weight: 900;
    letter-spacing: 1px;
    font-size: 40px;
    @media @mobile {
      font-size: 32px / @pv;
      line-height: 42px / @pv;
    }
  }
  h4 {
    margin-bottom: 40px;
    text-align: center;
    color: #696969;
    @media @desktop {
      width: 570px;
    }
    @media @mobile {
      margin-bottom: 24px / @pv;
    }
  }
  button {
    margin-bottom: 24px;
    @media @desktop {
      //width: 370px;
    }
    @media @mobile {
      margin-bottom: 24px / @pv;
      width: 100%;
    }
  }
  a {
    text-decoration: underline;
    font-weight: bold;
    font-size: 14px;
    color: #000;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
}

.recover-container {
  .other-sign {
    height: auto;
    margin-bottom: 40px;
    @media @mobile {
      display: none;
    }
  }
  .field-form-items {
    width: 100%;
    margin-bottom: 24px;
  }
}

.recover-info-modal {
  .info-container {
    p {
      padding: 0 54px;
    }
  }
}

.change-pwd-container {
  @media @mobile {
    padding-bottom: 10vw !important;
  }
}

.sign-pop {
  width: 320px;
  .ant-popover-title {
    font-weight: 900;
  }
}

.sign-popover-modal {
  .ant-modal {
    width: calc(100% - 60px) !important;
    margin: 0 30px / @pv;
  }
  .ant-modal-footer {
    button:first-child {
      display: none;
    }
    button {
      background-color: #000;
      color: #00ff6d;
      border: 0;
      font-weight: bold;
    }
  }
}
