@import "../theme";

.account-setting-container {
  color: @primary-dark;

  h1 {
    color: @primary-dark;
    font-size: 45px;
    font-weight: 900;
  }

  .avatar-section {
    h6 {
      font-weight: 900;
      padding-left: 30px;
    }

    .avatar-edit {
      margin-top: 30px;
    }
  }

  .profile-section {
    padding: 32px;
    margin-bottom: 24px;
    background: #fff;
    border: 1px solid #e0e0e0;
    box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.08);
    .flex > .ant-form-item {
      flex: 1;
      &:first-child {
        margin-right: 16px;
      }
    }
    position: relative;
    .role-to-artist-btn {
      position: absolute;
      right: 32px;
      top: 96px;
      @media @mobile {
        position: inherit;
      }
    }
  }
  .save-btn {
    margin-bottom: 8px;
  }

  .section-changepwd {
    padding: 32px;
    margin-bottom: 24px;
    background: #fff;
    border: 1px solid #e0e0e0;
    box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.08);
  }
}

.document-container {
  position: relative;
  .search-wrap {
    margin-bottom: 24px;
    .field-form-items {
      margin-right: 16px;
      @media @mobile {
        flex: 1;
        margin: 10px 0;
        &:first-child {
          margin-right: 10px / @pv;
        }
      }
    }
    .btns {
      .ant-btn {
        &:first-child {
          margin-right: 16px;
          @media @mobile {
            margin-right: 10px / @pv;
            margin-bottom: 10px / @pv;
          }
        }
        @media @mobile {
          width: 100%;
        }
      }
    }
    .ant-select {
      .ant-select-selector {
        height: 25.5px;
        padding-left: 0;
      }
    }
  }

  .ant-table-tbody > tr > td.doc-name {
    max-width: 180px;
  }
}

.document-modal {
  .ant-upload.ant-upload-drag {
    background: #f9f9f9;
    border: 1px dashed #e0e0e0;
    .anticon-cloud-upload {
      color: #00ff6d !important;
      font-size: 36px;
    }
  }
  .ant-upload-list {
    .ant-upload-list-item {
      padding: 16px;
      border: 1px solid #e0e0e0;
      margin-top: 16px;
      height: auto;
    }
  }
  .ant-input,
  .ant-picker,
  .ant-select {
    width: 100%;
    height: 26px;
    position: relative;
    top: -2px;
    .ant-select-selector {
      height: 100%;
      border: none !important;
      box-shadow: none !important;
    }
    border: none;
  }
}
