@import "@/styles/theme.less";
.admin-events-container {
  .search-container {
    .filter-item {
      width: 56px;
      height: 56px;
      flex: 0 0 56px;
      border: 1px solid #e0e0e0;
      margin-left: 16px;
      cursor: pointer;
    }
  }
  .data-chart-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 0;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.08);
    margin-bottom: 24px;
    span {
      flex: 1;
      padding-left: 32px;
      &:not(:last-child) {
        border-right: 1px solid #e0e0e0;
      }
      i {
        display: block;
        width: 100%;
        &:first-child {
          text-transform: uppercase;
          font-size: 12px;
          font-weight: 700;
          color: #a0a0a0;
          letter-spacing: 1px;
          margin-bottom: 8px;
        }
        &:last-child {
          font-family: RobotoBold;
          font-size: 32px;
          font-weight: 900;
          line-height: 42px;
        }
      }
    }
  }
}

.admin-sponsorship-modal {
  .ant-select-selector {
    max-width: 320px;
    overflow: hidden;
    .ant-select-selection-overflow {
      white-space: nowrap;
      flex-wrap: nowrap;
    }
  }
}

.step-container {
  padding-top: 86px;
  justify-content: center !important;
  img {
    height: 130px;
    width: 130px;
    margin-bottom: 40px;
  }
  h2 {
    margin-bottom: 0px !important;
  }
  p {
    color: #696969;
    margin-bottom: 24px;
  }
  .ant-steps {
    padding: 48px 24px 32px;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.08);
    margin-bottom: 40px;
    .ant-steps-item-finish
      > .ant-steps-item-container
      > .ant-steps-item-tail::after {
      background-color: #00ff6d;
    }
    .ant-steps-item-tail::after {
      height: 1px;
    }
    .ant-steps-icon {
      top: -14px;
      left: -15px;
    }
    .ant-steps-item-content {
      margin-top: 32px;
    }
    .border-item {
      border-radius: 100%;
      width: 34px;
      height: 34px;
      line-height: 32px;
      text-align: center;
      display: block;
      border: 1px solid #00ff6d;
      color: #000;
      font-weight: normal;
      font-size: 16px;
      background-color: #fff;
    }
    .ant-steps-item-wait {
      .border-item {
        border: 1px solid #e0e0e0;
        color: #e0e0e0;
      }
    }
  }
  button {
    margin-bottom: 24px;
  }
  a {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    color: #000;
    text-decoration: underline;
    letter-spacing: 1px;
    font-family: RobotoBold;
  }
}
