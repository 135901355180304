@import "@/styles/theme.less";

.admin-coupons-container {
  .search-container {
    .left-wrap {
      flex: 0 0 calc(100% - 190px);
    }
    button.ant-btn-primary {
      height: 58px !important;
    }
  }
}

.admin-coupons-modal {
  .ant-select-selector {
    max-width: 320px;
    overflow: hidden;
    .ant-select-selection-overflow {
      white-space: nowrap;
      flex-wrap: nowrap;
    }
  }
  .switch-wrap {
    margin-bottom: 24px;
    & > span {
      color: #000;
      font-weight: 900;
    }
    .switch-item {
      & > span {
        margin-right: 8px;
      }
    }
  }
}
